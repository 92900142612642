import { useProfile } from '@/composables/profile/useProfile'
import { computed } from 'vue'
import { Roles } from '@/services/roles'

export const useProfileRoles = () => {
  const { profileData } = useProfile()

  const isProfileAdmin = computed(() => {
    return profileData.value?.role === Roles.ADMIN
  })

  const isProfileManager = computed(() => {
    return profileData.value?.role === Roles.MANAGER
  })

  const isProfileMember = computed(() => {
    return profileData.value?.role === Roles.MEMBER
  })

  const isProfileStaff = computed(() => {
    return profileData.value?.role === Roles.STAFF
  })

  const isProfileAdminManager = computed(() => {
    return isProfileAdmin.value || isProfileManager.value
  })

  return {
    isProfileAdmin,
    isProfileManager,
    isProfileMember,
    isProfileStaff,
    isProfileAdminManager,
  }
}
