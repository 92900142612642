import axios from '@/libs/axios'

const PERMISSIONS = 'PERMISSIONS'
export const GET_PERMISSIONS = 'GET_PERMISSIONS'
export const IS_PERMISSION_ALLOW = 'IS_PERMISSION_ALLOW'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const FETCH_PERMISSIONS = 'FETCH_PERMISSIONS'

const state = () => ({
  [PERMISSIONS]: [],
})

const getters = {
  [GET_PERMISSIONS]: state => state[PERMISSIONS],
  [IS_PERMISSION_ALLOW]: state => permission =>
    !!state[PERMISSIONS].find(prm => prm === permission),
}

const mutations = {
  [SET_PERMISSIONS](state, data) {
    state[PERMISSIONS] = data
  },
}

const actions = {
  async [FETCH_PERMISSIONS]({ commit }) {
    try {
      const { data } = await axios.get(`choices/permissions`)
      commit(SET_PERMISSIONS, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
