import axios from '@/libs/axios'
import { objectToSelectOptions } from '@/services/utils'

export const CONSTANTS_RESPONSE = 'CONSTANTS_RESPONSE'
const SET_CONSTANTS_RESPONSE = 'SET_CONSTANTS_RESPONSE'
export const FETCH_CONSTANTS_RESPONSE = 'FETCH_CONSTANTS_RESPONSE'

export const GET_FUND_STRUCTURE = 'GET_FUND_STRUCTURE'
export const GET_FUND_PORTFOLIO_STRUCTURE = 'GET_FUND_PORTFOLIO_STRUCTURE'
export const GET_DISTRIBUTION_POLICY = 'GET_DISTRIBUTION_POLICY'
export const GET_DIVIDEND_TYPE = 'GET_DIVIDEND_TYPE'
export const GET_EXCHANGE = 'GET_EXCHANGE'
export const GET_FETCH_NAV = 'GET_FETCH_NAV'
export const GET_FINANCIAL_YEAR_END = 'GET_FINANCIAL_YEAR_END'
export const GET_FREQUENCY = 'GET_FREQUENCY'
export const GET_FUND_EVENT_STATUS = 'GET_FUND_EVENT_STATUS'
export const GET_NAV_FREQ = 'GET_NAV_FREQ'
export const GET_REASON = 'GET_REASON'
export const GET_INCORPORATED_STATE = 'GET_INCORPORATED_STATE'
export const GET_LOCATION_STATE = 'GET_LOCATION_STATE'
export const GET_DATA_TYPE = 'GET_DATA_TYPE'
export const GET_PAYOFF_PROFILE = 'GET_PAYOFF_PROFILE'
export const GET_FAIR_VALUE_LEVEL = 'GET_FAIR_VALUE_LEVEL'
export const GET_UNITS = 'GET_UNITS'
export const GET_ISSUER = 'GET_ISSUER'
export const GET_INTEREST = 'GET_INTEREST'
export const GET_REFERENCE = 'GET_REFERENCE'

const state = () => ({
  [CONSTANTS_RESPONSE]: null,
})

const getters = {
  [GET_FUND_STRUCTURE]: state => objectToSelectOptions(state[CONSTANTS_RESPONSE]?.structure ?? {}),
  [GET_FUND_PORTFOLIO_STRUCTURE]: state =>
    objectToSelectOptions(state[CONSTANTS_RESPONSE]?.portfolio_structure ?? {}),
  [GET_DISTRIBUTION_POLICY]: state =>
    objectToSelectOptions(state[CONSTANTS_RESPONSE]?.distribution_policy ?? {}),
  [GET_DIVIDEND_TYPE]: state =>
    objectToSelectOptions(state[CONSTANTS_RESPONSE]?.dividend_type ?? {}),
  [GET_EXCHANGE]: state => objectToSelectOptions(state[CONSTANTS_RESPONSE]?.exchange ?? {}),
  [GET_FETCH_NAV]: state => objectToSelectOptions(state[CONSTANTS_RESPONSE]?.fetch_nav ?? {}),
  [GET_FINANCIAL_YEAR_END]: state =>
    objectToSelectOptions(state[CONSTANTS_RESPONSE]?.financial_year_end ?? {}),
  [GET_FREQUENCY]: state => objectToSelectOptions(state[CONSTANTS_RESPONSE]?.frequency ?? {}),
  [GET_FUND_EVENT_STATUS]: state =>
    objectToSelectOptions(state[CONSTANTS_RESPONSE]?.fund_event__status ?? {}),
  [GET_NAV_FREQ]: state => objectToSelectOptions(state[CONSTANTS_RESPONSE]?.nav_freq ?? {}),
  [GET_REASON]: state => objectToSelectOptions(state[CONSTANTS_RESPONSE]?.reason ?? {}),
  [GET_INCORPORATED_STATE]: state =>
    objectToSelectOptions(state[CONSTANTS_RESPONSE]?.incorporated_state ?? {}),
  [GET_LOCATION_STATE]: state =>
    objectToSelectOptions(state[CONSTANTS_RESPONSE]?.location_state ?? {}),
  [GET_DATA_TYPE]: state => objectToSelectOptions(state[CONSTANTS_RESPONSE]?.data_type ?? {}),
  [GET_PAYOFF_PROFILE]: state =>
    objectToSelectOptions(state[CONSTANTS_RESPONSE]?.payoff_profile ?? {}),
  [GET_FAIR_VALUE_LEVEL]: state =>
    objectToSelectOptions(state[CONSTANTS_RESPONSE]?.fair_value_level ?? {}),
  [GET_UNITS]: state => objectToSelectOptions(state[CONSTANTS_RESPONSE]?.units ?? {}),
  [GET_ISSUER]: state => objectToSelectOptions(state[CONSTANTS_RESPONSE]?.issuer ?? {}),
  [GET_INTEREST]: state => objectToSelectOptions(state[CONSTANTS_RESPONSE]?.interest ?? {}),
  [GET_REFERENCE]: state => objectToSelectOptions(state[CONSTANTS_RESPONSE]?.reference ?? {}),
}

const mutations = {
  [SET_CONSTANTS_RESPONSE](state, value) {
    state[CONSTANTS_RESPONSE] = value
  },
}

let loading

const actions = {
  async [FETCH_CONSTANTS_RESPONSE]({ commit }) {
    if (loading) return

    loading = true
    try {
      const { data } = await axios.get('choices/constants')
      commit(SET_CONSTANTS_RESPONSE, data)
    } catch (e) {
      e.handleGlobally && e.handleGlobally()
    } finally {
      loading = false
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
