import { $themeBreakpoints } from '@themeConfig'
import funds from '@/store/app/funds'
import treemap from '@/store/app/treemap'
import bdcModule from '@/store/app/bdc'
import correlation from '@/store/app/correlation'
import portfolio from '@/store/app/portfolio'
import portfolio_details from '@/store/app/portfolio_details'
import profile from '@/store/app/profile'
import countries from '@/store/app/countries'
import permission from '@/store/app/permission'
import constants from '@/store/app/constants'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {},
  modules: {
    funds,
    treemap,
    bdcModule,
    correlation,
    portfolio,
    portfolio_details,
    profile,
    countries,
    permission,
    constants,
  },
}
