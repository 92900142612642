import axios from '@/libs/axios'

const HEATMAP = 'GROUP_NAME'

export const GET_HEATMAP = 'GET_GROUP_NAME'

export const SET_HEATMAP = 'SET_GROUP_NAME'

export const FETCH_HEATMAP = 'FETCH_HEATMAP'

const state = {
  [HEATMAP]: [],
}

const getters = {
  [GET_HEATMAP]: state => state[HEATMAP],
}

const mutations = {
  [SET_HEATMAP](state, data) {
    state[HEATMAP] = data
  },
}

const actions = {
  async [FETCH_HEATMAP]({ commit }) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_REQUEST_API}cefdata/heatmap`)
      commit(SET_HEATMAP, data?.results)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
