import axios from '@/libs/axios'

const COUNTRIES_DATA = 'COUNTRIES_DATA'
const STATES_DATA = 'STATES_DATA'
export const GET_COUNTRIES_DATA = 'GET_COUNTRIES_DATA'
export const SET_COUNTRIES_DATA = 'SET_COUNTRIES_DATA'
export const FETCH_COUNTRIES_DATA = 'FETCH_COUNTRIES_DATA'
export const GET_STATES_DATA = 'GET_STATES_DATA'
export const SET_STATES_DATA = 'SET_STATES_DATA'
export const FETCH_STATES_DATA = 'FETCH_STATES_DATA'

const state = () => ({
  [COUNTRIES_DATA]: [],
  [STATES_DATA]: [],
})

const getters = {
  [GET_COUNTRIES_DATA]: state => state[COUNTRIES_DATA],
  [GET_STATES_DATA]: state => state[STATES_DATA],
}

const mutations = {
  [SET_COUNTRIES_DATA](state, data) {
    state[COUNTRIES_DATA] = Object.keys(data).map(key => ({
      key: data[key],
      value: key,
    }))
  },
  [SET_STATES_DATA](state, data) {
    state[STATES_DATA] = Object.keys(data).map(key => ({
      key: data[key],
      value: key,
    }))
  },
}

const actions = {
  async [FETCH_COUNTRIES_DATA]({ commit }) {
    try {
      const { data: countriesData } = await axios.get(
        `${process.env.VUE_APP_REQUEST_API}choices/countries`,
      )
      commit(SET_COUNTRIES_DATA, { ...countriesData })
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
  async [FETCH_STATES_DATA]({ commit }) {
    try {
      const { data: statesData } = await axios.get(
        `${process.env.VUE_APP_REQUEST_API}choices/states`,
      )
      commit(SET_STATES_DATA, { ...statesData })
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
