import axios from '@/libs/axios'

const PUBLIC_PAGE = 'PUBLIC_PAGE'

export const GET_PUBLIC_PAGE = 'GET_PUBLIC_PAGE'

export const SET_PUBLIC_PAGE = 'SET_PUBLIC_PAGE'

export const FETCH_PUBLIC_PAGE = 'FETCH_PUBLIC_PAGE'

const state = {
  [PUBLIC_PAGE]: [],
}

const getters = {
  [GET_PUBLIC_PAGE]: state => state[PUBLIC_PAGE],
}

const mutations = {
  [SET_PUBLIC_PAGE](state, data) {
    state[PUBLIC_PAGE] = data
  },
}

const actions = {
  async [FETCH_PUBLIC_PAGE]({ commit }) {
    try {
      const { data } = await axios.get(`${process.env.VUE_APP_REQUEST_API}cefdata/bdcpublicpage/`)
      commit(SET_PUBLIC_PAGE, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
