import axios from '@/libs/axios'
import { computed, reactive, ref, watch } from 'vue'
import { useProfileRoles } from '@/composables/profile/useProfileRoles'
import { removeEmptyValuesFromObject } from '@/services/utils'

export const StructureOptions = Object.freeze({
  MODEL: 'model',
  PORTFOLIO: 'portfolio',
  WATCHLIST: 'watchlist',
})

export const useManagerPortfolio = () => {
  const refPortfolioManagerTable = ref(null)
  const loading = ref(false)
  const portfolioGroups = ref([])
  const tickers = ref([])
  const portfolioList = ref([])
  const detailPortfolio = ref(null)
  const portfolioName = ref(null)
  const filters = reactive({
    query: '',
    group: '',
    ticker: '',
    structure: '',
  })
  const computedFilters = computed(() => {
    return removeEmptyValuesFromObject(filters)
  })

  const perPage = ref(50)
  const totalPortfolios = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [50]
  const sortBy = ref(null)
  const isSortDirDesc = ref(true)
  const { isProfileManager } = useProfileRoles()

  const tableColumns = computed(() => [
    { key: 'id', sortable: true },
    {
      key: 'name',
      sortable: true,
      tdClass: 'widthColumnName',
      thClass: 'widthColumnName',
    },
    { key: 'group_name', sortable: true },
    { key: 'structure', sortable: true },
    { key: 'open' },
    ...(isProfileManager.value ? [{ key: 'assign_to' }, { key: 'edit' }, { key: 'remove' }] : []),
  ])

  const dataMeta = computed(() => {
    const localItemsCount = refPortfolioManagerTable.value
      ? refPortfolioManagerTable.value?.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to:
        totalPortfolios.value < perPage.value
          ? totalPortfolios.value
          : perPage.value * (currentPage.value - 1) + perPage.value,
      of: totalPortfolios.value,
    }
  })

  const structureOptions = [
    StructureOptions.MODEL,
    StructureOptions.PORTFOLIO,
    StructureOptions.WATCHLIST,
  ]

  const fetchPortfolioGroups = async () => {
    try {
      const { data } = await axios.get('choices/portfolio-groups')
      portfolioGroups.value = data
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  }

  const fetchTicker = async () => {
    try {
      const { data } = await axios.get('choices/tickers')
      tickers.value = data
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  }

  const fetchDetailPortfolio = async id => {
    loading.value = true
    try {
      const { data } = await axios.get(`cefdata/portfolio/${id}/`)
      detailPortfolio.value = data
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    } finally {
      loading.value = false
    }
  }
  const fetchPortfolioList = async () => {
    loading.value = true
    try {
      const { data } = await axios.get('cefdata/portfolio/', {
        params: {
          page:
            Object.values(computedFilters.value).length && currentPage.value === 1
              ? 1
              : currentPage.value,
          ...computedFilters.value,
        },
      })
      portfolioList.value = data
      totalPortfolios.value = data.count
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    } finally {
      loading.value = false
    }
  }

  const addPortfolio = async payload => {
    loading.value = true
    try {
      await axios.post('cefdata/portfolio/', payload)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
      throw error
    } finally {
      loading.value = false
    }
  }
  const editPortfolio = async (payload, id) => {
    loading.value = true
    try {
      await axios.put(`cefdata/portfolio/${id}/`, payload)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
      throw error
    } finally {
      loading.value = false
    }
  }
  const deletePortfolio = async id => {
    loading.value = true
    try {
      await axios.delete(`cefdata/portfolio/${id}/`)
      await fetchPortfolioList()
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    } finally {
      loading.value = false
    }
  }

  watch(computedFilters, () => {
    currentPage.value = 1
  })

  watch(
    [currentPage, perPage, filters],
    () => {
      fetchPortfolioList()
    },
    { deep: true },
  )

  return {
    loading,
    tableColumns,
    portfolioGroups,
    tickers,
    portfolioList,

    dataMeta,
    perPage,
    totalPortfolios,
    currentPage,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    structureOptions,
    refPortfolioManagerTable,

    fetchPortfolioGroups,
    fetchTicker,

    fetchPortfolioList,
    addPortfolio,
    deletePortfolio,
    editPortfolio,
    fetchDetailPortfolio,
    detailPortfolio,
    portfolioName,
    filters,
  }
}
