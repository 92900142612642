export default [
  {
    path: '/cefdata',
    name: 'cefdata',
    component: () => import('@/views/cef-data/CEFData.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/funds/:ticker',
    name: 'funds',
    component: () => import('@/views/funds/Fund.vue'),
  },
  {
    path: '/cefa-indexes/performance',
    name: 'performance',
    component: () => import('@/views/bdc-indexes/index-performance-table/IndexPerformance.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/cefa-indexes/',
    name: 'cefa-indexes/',
    component: () => import('@/views/bdc-indexes/bdc-table/BDCIndexes.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/cefa-indexes/:id/',
    name: 'cefa-indexes',
    component: () => import('@/views/major-cef/MajorCEF.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/cefu-data-definitions',
    name: 'CEFU Data Definitions',
    component: () => import('@/views/glossary/CEFUDataDefinitions.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/cef-data-pricing',
    name: 'CEF Data Pricing',
    component: () => import('@/views/glossary/CEFDataPricing.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/ici-flow-slides',
    name: 'ICI Flow Slides',
    component: () => import('@/views/glossary/ICIFlowSlides.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/fund-screener',
    name: 'cef-screener',
    component: () => import('@/views/cef-screener/Aicalliance.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/discount',
    name: 'discount',
    component: () => import('@/views/cef-charts/discount/DiscountChartContent.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/market-yield',
    name: 'market-yield',
    component: () => import('@/views/cef-charts/yield/MarketYield.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/cef-performance',
    name: 'cef-performance',
    component: () => import('@/views/cef-charts/cef-performance/CEFPerformance.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/correlation',
    name: 'correlation',
    component: () => import('@/views/correlation/CorrelationContent.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/bdc',
    name: 'bdc',
    component: () => import('@/views/bdc-universe/BDCContent.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/bdcdashboard',
    name: 'bdcdashboard',
    component: () => import('@/views/bdc-dashboard/BDCDashboardWrapper.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/treemap',
    name: 'treemap',
    component: () => import('@/views/treemap-chart/TreemapContent'),
    meta: {
      auth: true,
    },
  },

  {
    path: '/candlestick',
    name: 'candlestick',
    component: () => import('@/views/candle-stick-chart/CandleStickContent'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/portfolios/:id',
    name: 'portfolios',
    component: () => import('@/views/portfolios/PortfoliosPage'),
    props: true,
    meta: {
      auth: true,
    },
  },
  {
    path: '/queries',
    name: 'queries',
    component: () => import('@/views/portfolios/PortfolioLookup'),
    props: true,
    meta: {
      auth: true,
    },
  },
  {
    path: '/nonlistedfunds',
    name: 'NonListedFunds',
    component: () => import('@/views/non-listed-funds/NonListedFunds'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/data-team/source',
    name: 'source',
    props: true,
    component: () => import('@/views/data-team/sources/DataTeamSource'),
    meta: {
      // auth: true,
      breadcrumb: [
        {
          text: 'Sources',
          to: { name: 'source' },
        },
      ],
    },
  },
  {
    path: '/data-team/dividends',
    name: 'dividends',
    props: true,
    component: () => import('@/views/data-team/dividends/DataTeamDividends'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Dividends',
          to: { name: 'dividends' },
        },
      ],
    },
  },
  {
    path: '/data-team/fund-asset',
    name: 'fund-asset',
    props: true,
    component: () => import('@/views/data-team/fund-asset/FundAsset'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Fund Asset',
          to: { name: 'fund-asset' },
        },
      ],
    },
  },
  {
    path: '/data-team/funds',
    name: '/data-team/funds',
    props: true,
    component: () => import('@/views/data-team/funds/DataTeamFunds.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Funds',
          to: { name: '/data-team/funds' },
        },
      ],
    },
  },
  {
    path: '/data-team/fund-holdings',
    name: 'fund-holdings',
    props: true,
    component: () => import('@/views/data-team/fund-holdings/FundHoldings'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Fund Holdings',
          to: { name: '/data-team/fund-holdings' },
        },
      ],
    },
  },
  {
    path: '/data-team/fund-holdings-bdc',
    name: 'fund-holdings-bdc',
    props: true,
    component: () => import('@/views/data-team/fund-holdings-bdc/FundHoldingsBDC'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Fund Holdings BDC',
          to: { name: '/data-team/fund-holdings-bdc' },
        },
      ],
    },
  },
  {
    path: '/data-team/securities',
    name: 'securities',
    props: true,
    component: () => import('@/views/data-team/securities/Securities'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Security',
          to: { name: '/data-team/securities' },
        },
      ],
    },
  },
  {
    path: '/data-team/companies',
    name: 'companies',
    props: true,
    component: () => import('@/views/data-team/companies/Companies'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Company',
          to: { name: '/data-team/companies' },
        },
      ],
    },
  },
  {
    path: '/data-team/expected-dividends',
    name: 'expected-dividends',
    props: true,
    component: () => import('@/views/data-team/expectedDividend/ExpectedDividends'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Expected Dividends',
          to: { name: 'expected-dividends' },
        },
      ],
    },
  },
  {
    path: '/data-team/price',
    name: 'price',
    props: true,
    component: () => import('@/views/data-team/price/DataTeamPrice'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Prices',
          to: { name: 'price' },
        },
      ],
    },
  },
  {
    path: '/data-team/assigned-data/outdated',
    name: 'assigned-data-outdated',
    props: true,
    component: () => import('@/views/data-team/assigned-data/outdated/OutdatedData'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Assigned Data',
          to: { name: 'assigned-data-outdated' },
        },
      ],
    },
  },
  {
    path: '/data-team/assigned-data/all',
    name: 'assigned-data-all',
    props: true,
    component: () => import('@/views/data-team/assigned-data/all/AllData'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Assigned Data',
          to: { name: 'assigned-data-all' },
        },
      ],
    },
  },
  {
    path: '/data-team/assigned-data/log',
    name: 'assigned-data-log',
    props: true,
    component: () => import('@/views/data-team/assigned-data/log/LogData'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Assigned Data',
          to: { name: 'assigned-data-log' },
        },
      ],
    },
  },
  {
    path: '/releases/press-releases',
    name: 'press-releases',
    props: true,
    component: () => import('@/views/releases/press-releases/PressReleases'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/releases/ownership',
    name: 'ownership',
    props: true,
    component: () => import('@/views/releases/ownership/OwnerShip'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/releases/n2-filings',
    name: 'n2-filings',
    props: true,
    component: () => import('@/views/releases/n2-filings/N2Filings'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/releases/sec-filings',
    name: 'sec-filings',
    props: true,
    component: () => import('@/views/releases/sec-filings/SecFilings'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/data-team/admin',
    name: 'admin',
    props: true,
    component: () => import('@/views/data-team/admin/AdminData'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Admin Data',
          to: { name: 'admin' },
        },
      ],
    },
  },
  {
    path: '/reports',
    name: 'reports',
    props: true,
    component: () => import('@/views/reports/ReportsPage'),
    meta: {
      breadcrumb: [
        {
          text: 'Reports',
          to: { name: 'reports' },
        },
      ],
    },
  },
]
