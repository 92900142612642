import { Roles } from '@/services/roles'

export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/set-password/:key',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/authentication/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/confirm-email/:key',
    name: 'auth-confirm-email',
    component: () => import('@/views/pages/authentication/ConfirmEmail.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/account-setting',
    name: 'pages-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users-setting',
    name: 'user-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Users List',
          active: true,
        },
      ],
      permission: [Roles.MANAGER],
    },
  },
  {
    path: '/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Users List',
          to: { name: 'user-list' },
        },
        {
          text: 'Users View',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/edit/:id',
    name: 'apps-users-edit',
    props: true,
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Users List',
          to: { name: 'user-list' },
        },
        {
          text: 'Users Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/portfolio-management',
    name: 'portfolio-management-page',
    component: () => import('@/views/pages/portfolio-management/PortfolioManagement.vue'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Portfolio Management',
          active: true,
        },
      ],
    },
  },
  {
    path: '/data-team/funds/edit/:id',
    name: 'fund-edit',
    props: true,
    component: () => import('@/views/data-team/funds/EditFund.vue'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Funds',
          to: { name: '/data-team/funds' },
        },
        {
          text: 'Fund Edit',
          active: true,
        },
      ],
    },
  },
]
