import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// axios
import axios from 'axios'
import { getCookie } from '@/services/useCookie'
import { useRouter } from 'vue-router/composables'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_REQUEST_API,
})

axiosIns.interceptors.request.use(
  config => {
    // Get token from localStorage
    const accessToken = getCookie('access-token')

    // If token is present add it to request's Authorization Header
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(undefined, function (error) {
  error.handleGlobally = errorHandler(error)

  return Promise.reject(error)
})

const transformErrorMessage = errors => {
  let error = []
  for (const [key, value] of Object.entries(errors)) {
    error += `<span>${key}:</span> ${value} <br>`
  }
  return error
}

const errorHandler = error => {
  return () => {
    const statusCode = error.response ? error.response.status : null
    switch (statusCode) {
      case 400:
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error occurred with this request',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: transformErrorMessage(error.response?.data),
          },
        })
        break
      case 401:
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please login to access this resource',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        useRouter().push('/login')
        break
      case 404:
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'The requested resource does not exist or has been deleted',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        break
      case 500:
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: '500 Internal Server Error',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Server error response code indicates that the server encountered an unexpected condition that prevented it from fulfilling the request.',
          },
        })
        break
    }
  }
}

Vue.prototype.$http = axiosIns

export default axiosIns
