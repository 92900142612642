import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn } from '@/services/useCookie'
import chartsMaps from './routes/views'
import pages from './routes/pages'
import { useProfile } from '@/composables/profile/useProfile'
import { useCountries } from '@/composables/useCountries'

Vue.use(VueRouter)

const RouteNames = Object.freeze({
  LOGIN: 'auth-login',
  ERROR_404: 'error-404',
})

const router = new VueRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'cefdata' } },
    ...chartsMaps,
    ...pages,
    {
      path: '*',
      redirect: RouteNames.ERROR_404,
    },
  ],
})

/**
 * A pipeline for route guards.
 * The pipeline takes a list of functions, each function accepts parameters:
 *   - store: app store
 *   - to: the router "to" param
 *   - isInitialized: computed store property "isInitialized"
 *   - profileRole: current user role
 *   - redirectToLogin: alias to the 'login' route
 *   - next: function that guard should call to pass control to the next guard
 *  Guard should return value to stop any further guards execution. And should call next() to pass
 *  control to the next guard
 */

function useGuards(to, from, next) {
  const isInitialized = isUserLoggedIn()
  const { profileRole } = useProfile()
  const redirectToLogin = { name: RouteNames.LOGIN, query: { redirect: to.fullPath } }

  return {
    async next(guard) {
      await guard({ to, from, next, isInitialized, profileRole, redirectToLogin })
      return this
    },
  }
}

const redirectToLoginIfNecessary = ({ next, isInitialized, to }) => {
  if (!isInitialized && to.meta.resource !== 'Auth') {
    return next({ name: RouteNames.LOGIN, query: { redirect: to.fullPath } })
  }
  return next()
}

const setInitialized = async ({ to, redirectToLogin, next }) => {
  if (to?.meta.resource === 'Auth') {
    return next()
  }
  const { fetchProfileData, profileData } = useProfile()
  const { fetchCountries, fetchStates, states, countries } = useCountries()
  try {
    if (!profileData.value) {
      await fetchProfileData()
    }

    if (countries.value.length < 1) {
      await fetchCountries()
    }

    if (states.value.length < 1) {
      await fetchStates()
    }

    return next()
  } catch (e) {
    // Redirect to login on error, most likely 401 or 403 when authenticating
    return next(redirectToLogin)
  }
}

const checkRouteRestrictedByRole = ({ to, next, profileRole }) => {
  const { permission = [] } = to.meta
  // check if route is restricted by role
  if (permission.length && !permission.includes(profileRole.value)) {
    // role not authorised so redirect to error-404 page
    return next({ name: RouteNames.ERROR_404 })
  }
  return next()
}

export const setRouteGuards = router => {
  router.beforeEach((...args) =>
    useGuards(...args)
      .next(redirectToLoginIfNecessary)
      .then(guard => guard.next(setInitialized))
      .then(guard => guard.next(checkRouteRestrictedByRole)),
  )
}

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
