import axios from 'axios'

export const CHART_GROUPS = 'CHART_GROUPS'

export const GET_CHART_GROUPS = 'GET_CHART_GROUPS'

export const SET_CHART_GROUPS = 'SET_CHART_GROUPS'

export const FETCH_CHART_GROUPS = 'FETCH_CHART_GROUPS'

const state = () => ({
  [CHART_GROUPS]: [],
})

const getters = {
  [GET_CHART_GROUPS]: state => state[CHART_GROUPS],
}

const mutations = {
  [SET_CHART_GROUPS](state, payload) {
    state[CHART_GROUPS] = [...payload]
  },
}

const actions = {
  async [FETCH_CHART_GROUPS]({ commit }) {
    try {
      const { data } = await axios.get(
        `${process.env.VUE_APP_REQUEST_API}cefdata/chartgroups/?filter=c`,
      )
      commit(SET_CHART_GROUPS, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
