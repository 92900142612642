import axios from '@/libs/axios'

const PROFILE_DATA = 'PROFILE_DATA'
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA'
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA'
export const FETCH_PROFILE_DATA = 'FETCH_PROFILE_DATA'

const PROFILE_PORTFOLIO_PERMISSIONS = 'PROFILE_PORTFOLIO_PERMISSIONS'
export const GET_PROFILE_PORTFOLIO_PERMISSIONS = 'GET_PROFILE_PORTFOLIO_PERMISSIONS'
export const SET_PROFILE_PORTFOLIO_PERMISSIONS = 'SET_PROFILE_PORTFOLIO_PERMISSIONS'
export const FETCH_PROFILE_PORTFOLIO_PERMISSIONS = 'FETCH_PROFILE_PORTFOLIO_PERMISSIONS'

const state = () => ({
  [PROFILE_DATA]: null,
  [PROFILE_PORTFOLIO_PERMISSIONS]: {},
})

const getters = {
  [GET_PROFILE_DATA]: state => state[PROFILE_DATA],
  [GET_PROFILE_PORTFOLIO_PERMISSIONS]: state => state[PROFILE_PORTFOLIO_PERMISSIONS],
}

const mutations = {
  [SET_PROFILE_DATA](state, data) {
    state[PROFILE_DATA] = {
      ...data,
      country_code: !data.country_code ? 'US' : data.country_code,
    }
  },
  [SET_PROFILE_PORTFOLIO_PERMISSIONS](state, permissions) {
    state[PROFILE_PORTFOLIO_PERMISSIONS] = permissions
  },
}

const actions = {
  async [FETCH_PROFILE_DATA]({ commit }) {
    try {
      const { data: profileData } = await axios.get(
        `${process.env.VUE_APP_REQUEST_API}account/user-profile`,
      )
      commit(SET_PROFILE_DATA, {
        ...profileData,
        country_code: profileData.country_code,
      })
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
  async [FETCH_PROFILE_PORTFOLIO_PERMISSIONS]({ commit, getters }) {
    try {
      const { data: permissions } = await axios.get(
        `account/user-portfolio-permissions/${getters[GET_PROFILE_DATA]?.id}/`,
      )
      commit(SET_PROFILE_PORTFOLIO_PERMISSIONS, permissions)
    } catch (e) {
      commit(SET_PROFILE_PORTFOLIO_PERMISSIONS, [])
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
