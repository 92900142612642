import {
  FETCH_PROFILE_DATA,
  FETCH_PROFILE_PORTFOLIO_PERMISSIONS,
  GET_PROFILE_DATA,
  GET_PROFILE_PORTFOLIO_PERMISSIONS,
} from '@/store/app/profile'
import { FETCH_PERMISSIONS } from '@/store/app/permission'
import { computed } from 'vue'
import store from '@/store'

export const useProfile = () => {
  const profileData = computed(() => {
    return store.getters[`app/profile/${GET_PROFILE_DATA}`]
  })
  const profilePermissions = computed(
    () => store.getters[`app/profile/${GET_PROFILE_PORTFOLIO_PERMISSIONS}`]?.results,
  )

  const profileUsername = computed(() => {
    return `${profileData.value?.first_name} ${profileData.value?.last_name}`
  })

  const profileRole = computed(() => profileData.value?.role)

  const fetchProfileData = async () => {
    await store.dispatch(`app/profile/${FETCH_PROFILE_DATA}`)
    await store.dispatch(`app/profile/${FETCH_PROFILE_PORTFOLIO_PERMISSIONS}`)
    await store.dispatch(`app/permission/${FETCH_PERMISSIONS}`)
  }

  return {
    fetchProfileData,
    profileData,
    profileUsername,
    profileRole,
    profilePermissions,
  }
}
