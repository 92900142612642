export const removeEmptyValuesFromObject = object =>
  Object.fromEntries(Object.entries(object).filter(([, value]) => value))

export const objectToSelectOptions = object => {
  return Object.entries(object).map(([value, label]) => ({ label, value }))
}

export const mappedByKey = (array, key) => array.map(item => item[key])

export const modifyArray = data => {
  return Array.isArray(data) ? data?.join(',') : data
}
export const random = () =>
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)

export const groupByProperty = (objectArray, property) => {
  return objectArray.reduce(function (acc, obj) {
    const key = obj[property]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

export const removeDuplicateObject = array =>
  Object.values(array.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {}))

export const formatNumber = (value = 0, digits = 0, format = {}) => {
  if (!value) return 0
  return new Intl.NumberFormat([], {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
    ...format,
  }).format(value)
}

export const isValidURL = urlString => {
  try {
    new URL(urlString)
    return true
  } catch (error) {
    return false
  }
}

export const truncateString = (str, num) => {
  const isObject = Object.prototype.toString.call(str) === '[object Object]'

  if (isObject) str = JSON.stringify(str)
  if (typeof str !== 'string') return str
  if (str.length <= num) return str

  return str.slice(0, num) + '...'
}
