import axios from '@/libs/axios'
import moment from 'moment'
import { formatNumber } from '@/services/utils'
import { StructureOptions } from '@/composables/manager/useManagerPortfolio'

const PORTFOLIO_META = 'PORTFOLIO_META'
const PORTFOLIO_BASIC_VIEW = 'PORTFOLIO_BASIC_VIEW'
const PORTFOLIO_FULL_VIEW = 'PORTFOLIO_FULL_VIEW'
const PORTFOLIO_SUMMARY = 'PORTFOLIO_SUMMARY'
const IS_LOADING = 'IS_LOADING'

export const SET_PORTFOLIO_META = 'SET_PORTFOLIO_META'
export const SET_PORTFOLIO_BASIC_VIEW = 'SET_PORTFOLIO_BASIC_VIEW'
export const SET_PORTFOLIO_FULL_VIEW = 'SET_PORTFOLIO_FULL_VIEW'
export const SET_PORTFOLIO_SUMMARY = 'SET_PORTFOLIO_SUMMARY'
export const SET_IS_LOADING = 'SET_IS_LOADING'

export const GET_PORTFOLIO_META = 'GET_PORTFOLIO_META'
export const GET_PORTFOLIO_BASIC_VIEW = 'GET_PORTFOLIO_BASIC_VIEW'
export const GET_PORTFOLIO_FULL_VIEW = 'GET_PORTFOLIO_FULL_VIEW'
export const GET_PORTFOLIO_SUMMARY = 'GET_PORTFOLIO_SUMMARY'
export const GET_IS_LOADING = 'GET_IS_LOADING'

export const FETCH_PORTFOLIO_META = 'FETCH_PORTFOLIO_META'
export const FETCH_PORTFOLIO_BASIC_VIEW = 'FETCH_PORTFOLIO_BASIC_VIEW'
export const FETCH_PORTFOLIO_FULL_VIEW = 'FETCH_PORTFOLIO_FULL_VIEW'
export const FETCH_PORTFOLIO_SUMMARY = 'FETCH_PORTFOLIO_SUMMARY'

// const getMarketTotal = data => {
//   let total = 0
//
//   Object.keys(data).map(function (key) {
//     const groupData = data[key].data
//
//     total = groupData.reduce((accumulator, object) => {
//       return accumulator + object.holding.market_value
//     }, total)
//   })
//   return total
// }

const getItemRow = x => {
  let optional_data = []

  if (x.portfolio_structure === StructureOptions.PORTFOLIO) {
    optional_data = [
      formatNumber(x.holding.weight, 2),
      x.holding.units,
      x.holding.market_value,
      formatNumber(x.datapoints?.net_assets, 2),
      formatNumber(x.datapoints?.total_assets, 2),
    ]
  }

  if (x.portfolio_structure === StructureOptions.MODEL) {
    optional_data = [formatNumber(x.holding.weight, 2)]
  }

  return {
    key: x.ticker,
    editable: true,
    delete: false,
    name: x.fund.name,
    value: [
      x.fund.name,
      ...optional_data,

      formatNumber(x.price?.disc, 2),
      x.datapoints.discount_avg_3yr,
      x.datapoints.discount_rel_range_3yr,
      x.datapoints.discount_comp,
      x.datapoints.tr_nav_comp_1yr,
      x.datapoints.discount_low_3yr,
      x.datapoints.discount_high_3yr,
      formatNumber(x.price?.mk_yield, 2),
      formatNumber(x.datapoints?.lev_adj_nav_yield, 2),
      x.datapoints.total_leverage_pct,
      x.datapoints.tr_nav_reinvested_1mo,
      x.datapoints.tr_nav_reinvested_3mo,
      x.datapoints.tr_nav_reinvested_6mo,
      x.datapoints.tr_nav_reinvested_1yr,
      x.datapoints.tr_nav_reinvested_3yr,
      x.fund.frequency,
      x.datapoints.current_distribution_amount,
      x.datapoints.distribution_amount_annualized,
      moment(x.datapoints.current_distribution_ex_date).format('MM/DD/YY'),
      moment(x.datapoints.current_distribution_payable_date).format('MM/DD/YY'),
      x.datapoints.duration,
      x.datapoints.beta_2yr_sp500,
    ],
  }
}

const getDiscountRow = x => {
  return {
    key: x.ticker,
    name: x.fund.name,
    value: [
      x.fund.name,
      formatNumber(x.holding.weight, 2),
      x.holding.units,
      formatNumber(x.price?.discount, 2),
      x.datapoints.discount_rel_90d,
      x.datapoints.discount_avg_1yr,
      x.datapoints.discount_avg_3yr,
      x.datapoints.z_stat_6mo,
      x.datapoints.z_stat_comp_6mo,
      x.datapoints.z_stat_1yr,
      x.datapoints.z_stat_comp_1yr,
      x.datapoints.discount_rel_range_3yr,
      x.datapoints.discount_low_3yr,
      x.datapoints.discount_high_3yr,
      x.datapoints.premium_days_1yr,
      x.datapoints.premium_days_3yr,
      x.datapoints.discount_comp,
    ],
  }
}

const getYieldRow = x => {
  return {
    key: x.ticker,
    name: x.fund.name,
    value: [
      x.fund.name,
      formatNumber(x.holding.weight, 2),
      x.holding.units,
      x.datapoints.lev_adj_nav_yield,
      formatNumber(x.price?.mk_yield, 2),
      formatNumber(x.holding?.after_tax_yield, 2),
      formatNumber(x.holding?.est_tax_friction, 2),
      formatNumber(x.holding?.tax_friction_pct, 2),
      formatNumber(x.holding?.tax_qdi_final, 2),
      formatNumber(x.holding?.tax_ordinary_final, 2),
      formatNumber(x.holding?.tax_lgain, 2),
      formatNumber(x.holding?.tax_roc, 2),
      x.datapoints.distribution_roc_1yr_pct,
      x.datapoints.distribution_roc_3yr_pct,
      x.datapoints.last_div_change,
      x.datapoints.last_div_change_date
        ? moment(x.datapoints.last_div_change_date).format('MM/DD/YY')
        : '',
      x.datapoints.div_growth_1yr,
      x.datapoints.div_growth_3yr,
      x.datapoints.distribution_roc_1yr_pct,
      x.datapoints.distribution_roc_3yr_pct,
      x.fund.frequency,
      x.datapoints.current_distribution_ex_date
        ? moment(x.datapoints.current_distribution_ex_date).format('MM/DD/YY')
        : '',
      x.datapoints.current_distribution_payable_date
        ? moment(x.datapoints.current_distribution_payable_date).format('MM/DD/YY')
        : '',
      x.fund.expected_date ? moment(x.fund.expected_date).format('MM/DD/YY') : '',
      `${formatNumber(x.fund?.pending_distribution, 2)}%`,
    ],
  }
}

const getLeverageRow = x => {
  return {
    key: x.ticker,
    name: x.fund.name,
    value: [
      x.fund.name,
      formatNumber(x.holding.weight, 2),
      x.holding.units,
      x.datapoints.total_leverage_pct,
      x.datapoints.structural_leverage_pct,
      x.datapoints.portfolio_leverage_pct,
      x.datapoints.rel_lev_cost,
      x.datapoints.non_lev_exp_ratio,
      x.datapoints.gross_exp_ratio_ex_lev_cost,
      x.datapoints.expense_ratio_pct,
    ],
  }
}

const getCorrelationRow = x => {
  return {
    key: x.ticker,
    name: x.fund.name,
    value: [
      x.fund.name,
      formatNumber(x.holding.weight, 2),
      x.holding.units,
      x.datapoints.nav_volatility_6mo,
      x.datapoints.nav_volatility_1yr,
      x.datapoints.nav_volatility_3yr,
      x.datapoints.price_volatility_6mo,
      x.datapoints.price_volatility_1yr,
      x.datapoints.price_volatility_3yr,
      x.datapoints.disc_stdev_6mo,
      x.datapoints.disc_stdev_1yr,
      x.datapoints.disc_stdev_3yr,
      x.datapoints.corr_nav_price_90d,
      x.datapoints.corr_nav_price_6mo,
      x.datapoints.corr_nav_price_1yr,
      x.datapoints.sharpe_ratio_price,
      x.datapoints.sharpe_ratio_nav,
      x.datapoints.beta_2yr_sp500,
    ],
  }
}

const getNavTrRow = x => {
  return {
    key: x.ticker,
    name: x.fund.name,
    value: [
      x.fund.name,
      formatNumber(x.holding.weight, 2),
      x.holding.units,
      x.datapoints.tr_nav_reinvested_1wk,
      x.datapoints.tr_nav_reinvested_1mo,
      x.datapoints.tr_nav_reinvested_3mo,
      x.datapoints.tr_nav_reinvested_6mo,
      x.datapoints.tr_nav_reinvested_1yr,
      x.datapoints.tr_nav_reinvested_3yr,
      x.datapoints.tr_nav_reinvested_qtd,
      x.datapoints.tr_nav_reinvested_ytd,
      x.datapoints.nav_return_6mo,
      x.datapoints.nav_return_1yr,
      x.datapoints.nav_return_3yr,
      x.datapoints.tr_nav_comp_3mo,
      x.datapoints.tr_nav_comp_6mo,
      x.datapoints.tr_nav_comp_1yr,
      x.datapoints.tr_nav_comp_3yr,
    ],
  }
}

const getAllocationsRow = x => {
  return {
    key: x.ticker,
    name: x.fund.name,
    value: [
      x.fund.name,
      formatNumber(x.holding.weight, 2),
      x.holding.units,
      x.allocation?.us_equity,
      x.allocation?.non_us_equity,
      x.allocation?.us_bonds,
      x.allocation?.non_us_bonds,
      x.datapoints?.common_stock_us,
      formatNumber(x.datapoints.bdc_allocation, 2),
      x.allocation?.reit_real_estate,
      x.allocation?.option_premium,
      x.allocation?.mlp_energy,
      x.allocation?.preferred_equity,
      formatNumber(x.allocation.municipal_alloc, 2),
      formatNumber(x.allocation.inv_grade_alloc, 2),
      x.allocation?.convertible,
      x.allocation?.loans_clo_debt,
      formatNumber(x.allocation.high_yield_alloc, 2),
    ],
  }
}

const getBondRow = x => {
  return {
    key: x.ticker,
    name: x.fund.name,
    value: [
      x.fund.name,
      formatNumber(x.holding.weight, 2),
      x.holding.units,
      x.datapoints.earnings_yield,
      x.datapoints.investment_grade,
      x.datapoints.non_investment_grade,
      x.datapoints.unrated_bonds,
      x.datapoints.coupon_avg,
      x.datapoints.duration,
      x.datapoints.maturity_weighted,
    ],
  }
}

const getMunicipalBondsRow = x => {
  return {
    key: x.ticker,
    name: x.fund.name,
    value: [
      x.fund.name,
      formatNumber(x.holding.weight, 2),
      x.holding.units,
      x.datapoints.unii_trend,
      x.datapoints.amt_pct,
      x.datapoints.insured,
      x.datapoints.bond_call_1yr,
      x.datapoints.bond_call_2yr,
      x.datapoints.average_bond_price,
    ],
  }
}

const getBDCRow = x => {
  return {
    key: x.ticker,
    name: x.fund.name,
    value: [
      x.fund.name,
      formatNumber(x.holding.weight, 2),
      x.holding.units,
      x.datapoints.portfolio_debt,
      x.datapoints.debt_to_equity,
      x.datapoints.sbic,
      x.datapoints.fixed_leverage_pct,
      x.datapoints.variable_debt,
      x.datapoints.libor_floor_average,
      x.datapoints.libor_floor_percentage,
      x.datapoints.lien1,
      x.datapoints.lien2,
      x.datapoints.other_loans,
      x.datapoints.non_loans,
      x.datapoints.clo_pct,
      x.datapoints.non_accrual,
      x.datapoints.average_loan_size,
      x.datapoints.base_management_fee_pct,
      x.datapoints.hurdle_rate,
      x.datapoints.high_water_mark,
    ],
  }
}

const getMLPRow = x => {
  return {
    key: x.ticker,
    name: x.fund.name,
    value: [
      x.fund.name,
      formatNumber(x.holding.weight, 2),
      x.holding.units,
      x.datapoints.mlp_type,
      x.datapoints.mlp,
      x.allocation.total_equity,
      x.allocation.total_bonds,
      x.datapoints.dtl_per_share,
      x.datapoints.dtl_per_share_adj_disc_pct,
    ],
  }
}

const getHoldingRow = x => {
  return {
    key: x.ticker,
    name: x.fund.name,
    value: [
      x.fund.name,
      formatNumber(x.holding.weight, 2),
      x.holding.units,
      x.datapoints.nport_bdc_holdings,
      x.datapoints.mbs_subprime,
      x.datapoints.mbs_agency,
      x.datapoints.mbs_non_agency,
      x.datapoints.convertible_pct,
      x.datapoints.big_cap_pct,
      x.datapoints.mid_cap_pct,
      x.datapoints.small_cap_pct,
      x.datapoints.hy_foreign_corp_pct,
      x.datapoints.hy_foreign_sovereign_pct,
    ],
  }
}

const getItemWeightedRow = (groupName, weightedData) => {
  return {
    key: '',
    editable: false,
    delete: false,
    name: groupName,
    value: [
      groupName,
      formatNumber(weightedData.weight, 2),
      '-',
      '-',
      '-',
      '-',
      formatNumber(weightedData.discount, 2),
      formatNumber(weightedData.discount_avg_3yr, 2),
      formatNumber(weightedData.discount_rel_range_3yr, 2),
      formatNumber(weightedData.discount_comp, 2),
      formatNumber(weightedData.tr_nav_comp_1yr, 2),
      formatNumber(weightedData.discount_low_3yr, 2),
      formatNumber(weightedData.discount_high_3yr, 2),
      formatNumber(weightedData.mk_yield, 2),
      formatNumber(weightedData.lev_adj_nav_yield, 2),
      formatNumber(weightedData.total_leverage_pct, 2),
      formatNumber(weightedData.tr_nav_reinvested_1mo, 2),
      formatNumber(weightedData.tr_nav_reinvested_3mo, 2),
      formatNumber(weightedData.tr_nav_reinvested_6mo, 2),
      formatNumber(weightedData.tr_nav_reinvested_1yr, 2),
      formatNumber(weightedData.tr_nav_reinvested_3yr, 2),

      '-',
      '-',
      '-',
      '-',
      '-',
      formatNumber(weightedData.duration, 2),
      formatNumber(weightedData.beta_2yr_sp500, 2),
    ],
  }
}
//
// const averageValue = (array, field1, field2) => {
//   return array
//     .reduce((total, curObj, index) => {
//       if (curObj[field1] && curObj[field1][field2]) {
//         total += +curObj[field1][field2]
//       }
//       return index === array.length - 1 ? total / array.length : total
//     }, 0)
//     .toFixed(2)
// }

const sumValue = (array, field1, field2) => {
  return array
    .reduce((total, curObj) => {
      if (curObj[field1] && curObj[field1][field2]) {
        total += +curObj[field1][field2]
      }
      return total
    }, 0)
    .toFixed(2)
}

const getWeightedAvrRow = (allGroupsData, weightedData) => {
  return [
    {
      key: 'Weighted Avr',
      value: [
        '100.00%',
        '-',
        sumValue(allGroupsData, 'holding', 'market_value'),
        sumValue(allGroupsData, 'datapoints', 'net_assets'),
        sumValue(allGroupsData, 'datapoints', 'total_assets'),
        formatNumber(weightedData.discount, 2),
        formatNumber(weightedData.discount_avg_3yr, 2),
        formatNumber(weightedData.discount_rel_range_3yr, 2),
        formatNumber(weightedData.discount_comp, 2),
        formatNumber(weightedData.tr_nav_comp_1yr, 2),
        formatNumber(weightedData.discount_low_3yr, 2),
        formatNumber(weightedData.discount_high_3yr, 2),
        formatNumber(weightedData.mk_yield, 2),
        formatNumber(weightedData.lev_adj_nav_yield, 2),
        formatNumber(weightedData.total_leverage_pct, 2),
        formatNumber(weightedData.tr_nav_reinvested_1mo, 2),
        formatNumber(weightedData.tr_nav_reinvested_3mo, 2),
        formatNumber(weightedData.tr_nav_reinvested_6mo, 2),
        formatNumber(weightedData.tr_nav_reinvested_1yr, 2),
        formatNumber(weightedData.tr_nav_reinvested_3yr, 2),

        '-',
        '-',
        '-',
        '-',
        '-',
        formatNumber(weightedData.duration, 2),
        formatNumber(weightedData.beta_2yr_sp500, 2),
      ],
    },
  ]
}

const getDiscountWeightedAvrRow = weightedData => {
  return [
    {
      key: 'Weighted Avr',
      value: [
        '100.00%',
        '-',
        formatNumber(weightedData.discount, 2),
        formatNumber(weightedData.discount_rel_90d, 2),
        formatNumber(weightedData.discount_avg_1yr, 2),
        formatNumber(weightedData.discount_avg_3yr, 2),
        formatNumber(weightedData.z_stat_6mo, 2),
        formatNumber(weightedData.z_stat_comp_6mo, 2),
        formatNumber(weightedData.z_stat_1yr, 2),
        formatNumber(weightedData.z_stat_comp_1yr, 2),
        formatNumber(weightedData.discount_rel_range_3yr, 2),
        formatNumber(weightedData.discount_low_3yr, 2),
        formatNumber(weightedData.discount_high_3yr, 2),
        formatNumber(weightedData.premium_days_1yr, 2),
        formatNumber(weightedData.premium_days_3yr, 2),
        formatNumber(weightedData.discount_comp, 2),
      ],
    },
  ]
}

const getYieldWeightedAvrRow = (allGroupsData, weightedData) => {
  return [
    {
      key: 'Weighted Avr',
      value: [
        '100.00%',
        '-',
        formatNumber(weightedData.lev_adj_nav_yield, 2),
        formatNumber(weightedData.mk_yield, 2),
        formatNumber(weightedData.after_tax_yield, 2),
        formatNumber(weightedData.est_tax_friction, 2),
        formatNumber(weightedData.last_div_change, 2),
        '',
        formatNumber(weightedData.div_growth_1yr, 2),
        formatNumber(weightedData.div_growth_3yr, 2),
        formatNumber(weightedData.distribution_roc_1yr_pct, 2),
        formatNumber(weightedData.distribution_roc_3yr_pct, 2),
        '',
        '',
        '',
        '',
        sumValue(allGroupsData, 'fund', 'pending_distribution'),
      ],
    },
  ]
}

const getLeverageWeightedAvrRow = weightedData => {
  return [
    {
      key: 'Weighted Avr',
      value: [
        '100.00%',
        '-',
        formatNumber(weightedData.total_leverage_pct, 2),
        formatNumber(weightedData.structural_leverage_pct, 2),
        formatNumber(weightedData.portfolio_leverage_pct, 2),
        formatNumber(weightedData.rel_lev_cost, 2),
        formatNumber(weightedData.non_lev_exp_ratio, 2),
        formatNumber(weightedData.expense_ratio_pct, 2),
      ],
    },
  ]
}

const getCorrelationWeightedAvrRow = weightedData => {
  return [
    {
      key: 'Weighted Avr',
      value: [
        '100.00%',
        '-',
        formatNumber(weightedData.nav_volatility_6mo, 2),
        formatNumber(weightedData.nav_volatility_1yr, 2),
        formatNumber(weightedData.nav_volatility_3yr, 2),
        formatNumber(weightedData.price_volatility_6mo, 2),
        formatNumber(weightedData.price_volatility_1yr, 2),
        formatNumber(weightedData.price_volatility_3yr, 2),

        formatNumber(weightedData.disc_stdev_6mo, 2),
        formatNumber(weightedData.disc_stdev_1yr, 2),
        formatNumber(weightedData.disc_stdev_3yr, 2),
        formatNumber(weightedData.corr_nav_price_90d, 2),
        formatNumber(weightedData.corr_nav_price_6mo, 2),
        formatNumber(weightedData.corr_nav_price_1yr, 2),
        formatNumber(weightedData.sharpe_ratio_price, 2),
        formatNumber(weightedData.sharpe_ratio_nav, 2),

        formatNumber(weightedData.beta_2yr_sp500, 2),
      ],
    },
  ]
}

const getNavTrWeightedAvrRow = weightedData => {
  return [
    {
      key: 'Weighted Avr',
      value: [
        '100.00%',
        '-',
        formatNumber(weightedData.tr_nav_reinvested_1wk, 2),
        formatNumber(weightedData.tr_nav_reinvested_1mo, 2),
        formatNumber(weightedData.tr_nav_reinvested_3mo, 2),
        formatNumber(weightedData.tr_nav_reinvested_6mo, 2),
        formatNumber(weightedData.tr_nav_reinvested_1yr, 2),
        formatNumber(weightedData.tr_nav_reinvested_3yr, 2),
        formatNumber(weightedData.tr_nav_reinvested_qtd, 2),
        formatNumber(weightedData.tr_nav_reinvested_ytd, 2),

        formatNumber(weightedData.nav_return_6mo, 2),
        formatNumber(weightedData.nav_return_1yr, 2),
        formatNumber(weightedData.nav_return_3yr, 2),
        formatNumber(weightedData.tr_nav_comp_3mo, 2),

        formatNumber(weightedData.tr_nav_comp_6mo, 2),
        formatNumber(weightedData.tr_nav_comp_1yr, 2),
        formatNumber(weightedData.tr_nav_comp_3yr, 2),
      ],
    },
  ]
}

const getAllocationsAvrRow = weightedData => {
  return [
    {
      key: 'Weighted Avr',
      value: [
        '100.00%',
        '-',
        formatNumber(weightedData.us_equity, 2),
        formatNumber(weightedData.non_us_equity, 2),
        formatNumber(weightedData.us_bonds, 2),
        formatNumber(weightedData.non_us_bonds, 2),
        formatNumber(weightedData.common_stock_us, 2),
        formatNumber(weightedData.bdc_allocation, 2),

        formatNumber(weightedData.reit_real_estate, 2),
        formatNumber(weightedData.option_premium, 2),
        formatNumber(weightedData.mlp_energy, 2),
        formatNumber(weightedData.preferred_equity, 2),
        formatNumber(weightedData.municipal_alloc, 2),
        formatNumber(weightedData.inv_grade_alloc, 2),

        formatNumber(weightedData.convertible, 2),
        formatNumber(weightedData.loans_clo_debt, 2),
        formatNumber(weightedData.high_yield_alloc, 2),
      ],
    },
  ]
}

const getBondAvrRow = weightedData => {
  return [
    {
      key: 'Weighted Avr',
      value: [
        '100.00%',
        '-',
        formatNumber(weightedData.earnings_yield, 2),
        formatNumber(weightedData.investment_grade, 2),
        formatNumber(weightedData.non_investment_grade, 2),
        formatNumber(weightedData.unrated_bonds, 2),
        formatNumber(weightedData.coupon_avg, 2),
        formatNumber(weightedData.duration, 2),
        formatNumber(weightedData.maturity_weighted, 2),
      ],
    },
  ]
}

const getMunicipalBondsAvrRow = weightedData => {
  return [
    {
      key: 'Weighted Avr',
      value: [
        '100.00%',
        '-',
        formatNumber(weightedData.unii_trend, 2),
        formatNumber(weightedData.amt_pct, 2),
        formatNumber(weightedData.insured, 2),
        formatNumber(weightedData.bond_call_1yr, 2),
        formatNumber(weightedData.bond_call_2yr, 2),
        formatNumber(weightedData.average_bond_price, 2),
      ],
    },
  ]
}

const getBDCAvrRow = weightedData => {
  return [
    {
      key: 'Weighted Avr',
      value: [
        '100.00%',
        '-',
        formatNumber(weightedData.portfolio_debt, 2),
        formatNumber(weightedData.debt_to_equity, 2),
        '-',
        formatNumber(weightedData.fixed_leverage_pct, 2),
        formatNumber(weightedData.variable_debt, 2),
        formatNumber(weightedData.libor_floor_average, 2),
        formatNumber(weightedData.libor_floor_percentage, 2),
        formatNumber(weightedData.lien1, 2),
        formatNumber(weightedData.lien2, 2),
        formatNumber(weightedData.other_loans, 2),
        formatNumber(weightedData.clo_pct, 2),
        formatNumber(weightedData.non_accrual, 2),
        formatNumber(weightedData.average_loan_size, 2),
        formatNumber(weightedData.base_management_fee_pct, 2),
        formatNumber(weightedData.hurdle_rate, 2),
        formatNumber(weightedData.high_water_mark, 2),
      ],
    },
  ]
}

const getMLPAvrRow = weightedData => {
  return [
    {
      key: 'Weighted Avr',
      value: [
        '100.00%',
        '-',
        '-',
        formatNumber(weightedData.mlp, 2),
        formatNumber(weightedData.total_equity, 2),
        formatNumber(weightedData.total_bonds, 2),
        formatNumber(weightedData.dtl_per_share, 2),
        formatNumber(weightedData.dtl_per_share_adj_disc_pct, 2),
      ],
    },
  ]
}

const getHoldingAvrRow = weightedData => {
  return [
    {
      key: 'Weighted Avr',
      value: [
        '100.00%',
        '-',
        formatNumber(weightedData.nport_bdc_holdings, 2),
        formatNumber(weightedData.mbs_subprime, 2),
        formatNumber(weightedData.mbs_agency, 2),
        formatNumber(weightedData.mbs_non_agency, 2),
        formatNumber(weightedData.convertible_pct, 2),
        formatNumber(weightedData.big_cap_pct, 2),
        formatNumber(weightedData.mid_cap_pct, 2),
        formatNumber(weightedData.small_cap_pct, 2),
        formatNumber(weightedData.hy_foreign_corp_pct, 2),
        formatNumber(weightedData.hy_foreign_sovereign_pct, 2),
      ],
    },
  ]
}

const getTotalReturnsItems = weightedAverage => {
  return [
    {
      ' ': 'Price',
      '1MO': weightedAverage?.tr_price_reinvested_1mo?.toFixed(2) + '%',
      '3MO': weightedAverage?.tr_price_reinvested_3mo?.toFixed(2) + '%',
      '6MO': weightedAverage?.tr_price_reinvested_6mo?.toFixed(2) + '%',
      '1YR': weightedAverage?.tr_price_reinvested_1yr?.toFixed(2) + '%',
      '3YR': weightedAverage?.tr_price_reinvested_3yr?.toFixed(2) + '%',
    },
    {
      ' ': 'NAV',
      '1MO': weightedAverage?.tr_nav_reinvested_1mo?.toFixed(2) + '%',
      '3MO': weightedAverage?.tr_nav_reinvested_3mo?.toFixed(2) + '%',
      '6MO': weightedAverage?.tr_nav_reinvested_6mo?.toFixed(2) + '%',
      '1YR': weightedAverage?.tr_nav_reinvested_1yr?.toFixed(2) + '%',
      '3YR': weightedAverage?.tr_nav_reinvested_3yr?.toFixed(2) + '%',
    },
  ]
}

const getMlpSpecificItems = weightedAverage => {
  return [
    {
      key: 'RoC (1yr)',
      value: weightedAverage.distribution_roc_1yr_pct_mlp?.toFixed(1) + '%',
    },
    {
      key: 'RoC (3yr)',
      value: weightedAverage.distribution_roc_3yr_pct_mlp?.toFixed(1) + '%',
    },
    { key: 'Portfolio MLP Exposure %', value: weightedAverage.mlp?.toFixed(1) },
    { key: '% Pipeline Transportation', value: weightedAverage.mlppt_mlp?.toFixed(1) },
    { key: '% Gathering Processing', value: weightedAverage.mlpgp_mlp?.toFixed(1) },
    {
      key: 'DTL Adj Disc',
      value: weightedAverage.dtl_per_share_adj_disc_pct_mlp?.toFixed(1) + '%',
    },
  ]
}
const getCefBondItems = weightedAverage => {
  return [
    {
      key: 'Earnings Coverage',
      value: weightedAverage.earnings_coverage?.toFixed(1) + '%',
    },
    {
      key: 'Investment Grade',
      value: weightedAverage.investment_grade?.toFixed(1) + '%',
    },
    {
      key: 'Non Investment Grade',
      value: weightedAverage.non_investment_grade?.toFixed(1) + '%',
    },
    {
      key: 'Unrated Bonds',
      value: weightedAverage.unrated_bonds?.toFixed(1) + '%',
    },
    {
      key: 'Duration',
      value: weightedAverage.duration?.toFixed(1),
    },
  ]
}

const getFullCefItems = (sum, weightedAverage) => {
  return [
    [
      {
        key: 'Discount',
        value: weightedAverage.discount?.toFixed(2) + '%',
      },
      {
        key: '1Yr Disc',
        value: weightedAverage.discount_avg_1yr?.toFixed(2) + '%',
      },
    ],
    [
      {
        key: '5Yr Disc',
        value: weightedAverage.discount_avg_5yr?.toFixed(2) + '%',
      },
      {
        key: 'Comp Discount',
        value: weightedAverage.discount_comp?.toFixed(2) + '%',
      },
    ],
    [
      {
        key: 'Mkt Yield',
        value: weightedAverage.mk_yield?.toFixed(2) + '%',
      },
      {
        key: 'Lev Adj NAV Yield',
        value: weightedAverage.lev_adj_nav_yield?.toFixed(2) + '%',
      },
    ],
    [
      {
        key: 'Comp 1YR NAV TR',
        value: weightedAverage.tr_nav_comp_1yr?.toFixed(2) + '%',
      },
      {
        key: '1Yr Price Volatility',
        value: weightedAverage.price_volatility_1yr?.toFixed(1),
      },
    ],
    [
      {
        key: '3Yr NAV Volatility',
        value: weightedAverage.nav_volatility_3yr?.toFixed(1),
      },

      {
        key: 'Cash Weighted Duration',
        value: weightedAverage.cash_duration?.toFixed(2),
      },
    ],
    [
      {
        key: 'Gross Asset Exposure',
        value: `$${sum.gross_asset_exposure?.toFixed(2)}`,
      },

      {
        key: '2 Year Beta',
        value: weightedAverage.beta_2yr_sp500?.toFixed(2),
      },
    ],
    [
      {
        key: '1Yr RoC',
        value: weightedAverage.distribution_roc_1yr_pct?.toFixed(2) + '%',
      },
      {
        key: '3Yr Dest RoC:',
        value: weightedAverage.dest_roc_3yr?.toFixed(2) + '%',
      },
    ],
    [
      {
        key: 'Estimated Tax Friction (3Yr)',
        value: weightedAverage.est_tax_friction_3yr.toFixed(2) + '%',
      },

      {
        key: 'Leverage:',
        value: weightedAverage.total_leverage_pct?.toFixed(2) + '%',
      },
    ],

    [
      {
        key: 'Leverage Cost:',
        value: weightedAverage.leverage_cost_pct?.toFixed(2) + '%',
      },

      {
        key: 'Non Lev Exp Ratio:',
        value: weightedAverage.non_lev_gross_exp_ratio?.toFixed(2) + '%',
      },
    ],
  ]
}

const getDiscountItems = data => {
  let items = []

  Object.keys(data).map(function (key) {
    const groupData = data[key].data

    groupData.map(x => {
      items = [...items, getDiscountRow(x)]
    })
  })

  return items
}

const getYieldItems = data => {
  let items = []

  Object.keys(data).map(function (key) {
    const groupData = data[key].data

    groupData.map(x => {
      items = [...items, getYieldRow(x)]
    })
  })

  return items
}

const getLeverageItems = data => {
  let items = []

  Object.keys(data).map(function (key) {
    const groupData = data[key].data

    groupData.map(x => {
      items = [...items, getLeverageRow(x)]
    })
  })

  return items
}
const getMonthlyIncome = monthlyIncome => {
  let items = []

  monthlyIncome.map(function (entry) {
    items = [
      ...items,
      {
        key: entry.month,
        value: `$${formatNumber(entry.income)} (${formatNumber(entry.percentage)}%)`,
      },
    ]
  })
  return items
}
const getCorrelationItems = data => {
  let items = []

  Object.keys(data).map(function (key) {
    const groupData = data[key].data

    groupData.map(x => {
      items = [...items, getCorrelationRow(x)]
    })
  })

  return items
}

const getNavTrItems = data => {
  let items = []

  Object.keys(data).map(function (key) {
    const groupData = data[key].data

    groupData.map(x => {
      items = [...items, getNavTrRow(x)]
    })
  })

  return items
}

const getAllocationsItems = data => {
  let items = []

  Object.keys(data).map(function (key) {
    const groupData = data[key].data

    groupData.map(x => {
      items = [...items, getAllocationsRow(x)]
    })
  })

  return items
}

const getBondItems = data => {
  let items = []

  Object.keys(data).map(function (key) {
    const groupData = data[key].data

    groupData.map(x => {
      if (
        x.group.main_group_category === 'B' ||
        x.group.group_name === 'Preferred Equity Leveraged'
      ) {
        items = [...items, getBondRow(x)]
      }
    })
  })

  return items
}

const getMunicipalBondsItems = data => {
  let items = []

  Object.keys(data).map(function (key) {
    const groupData = data[key].data

    groupData.map(x => {
      if (
        x.group.main_group_name === 'Single State Muni Bond Funds' ||
        x.group.main_group_name === 'National Muni Bond Funds'
      ) {
        items = [...items, getMunicipalBondsRow(x)]
      }
    })
  })

  return items
}

const getBDCItems = data => {
  let items = []

  Object.keys(data).map(function (key) {
    const groupData = data[key].data

    groupData.map(x => {
      if (x.allocation.is_dbc_type) {
        items = [...items, getBDCRow(x)]
      }
    })
  })

  return items
}

const getMLPItems = data => {
  let items = []

  Object.keys(data).map(function (key) {
    const groupData = data[key].data

    groupData.map(x => {
      if (x.group.group_name === 'MLP Funds') {
        items = [...items, getMLPRow(x)]
      }
    })
  })

  return items
}

const getHoldingItems = data => {
  let items = []

  Object.keys(data).map(function (key) {
    const groupData = data[key].data

    groupData.map(x => {
      items = [...items, getHoldingRow(x)]
    })
  })

  return items
}

const getDataTableItems = data => {
  let items = []
  let allGroupsData = []

  Object.keys(data).map(function (key) {
    const groupName = data[key].name
    const groupData = data[key].data
    const weightedData = data[key].weighted

    let weight_total = 0
    allGroupsData = [...allGroupsData, ...groupData]

    groupData.map(x => {
      weight_total = weight_total + x.holding.weight
      items = [...items, getItemRow(x)]
    })

    if (groupData.length > 0) {
      items = [...items, getItemWeightedRow(groupName, weightedData)]
    }
  })

  return items
}

const getAllGroupsData = data => {
  let allGroupsData = []

  Object.keys(data).map(function (key) {
    const groupData = data[key].data
    allGroupsData = [...allGroupsData, ...groupData]
  })

  return allGroupsData
}

const state = {
  [PORTFOLIO_META]: {
    data: {},
    create_update_string: null,
    create_update_string_for_manager: null,
  },
  [PORTFOLIO_BASIC_VIEW]: {
    items: [],
    footers: [],
    updateItems: [],
  },
  [PORTFOLIO_FULL_VIEW]: {
    discountItems: [],
    discountFooters: [],
    yieldItems: [],
    yieldFooters: [],
    leverageItems: [],
    leverageFooters: [],
    correlationItems: [],
    correlationFooters: [],
    navTrItems: [],
    navTrFooters: [],

    allocationsItems: [],
    allocationsFooters: [],

    bondItems: [],
    bondFooters: [],

    municipalBondItems: [],
    municipalBondFooters: [],

    BDCItems: [],
    BDCFooters: [],

    MLPItems: [],
    MLPFooters: [],

    holdingItems: [],
    holdingFooters: [],
  },
  [PORTFOLIO_SUMMARY]: {
    totalReturnsItems: [],
    cefBondItems: [],
    municipalBondHeader: [],
    municipalBondItems: [],
    portfoliosAssetsItems: [],
    incomeItems: [],
    fullCefItems: [],
    mlpSpecificItems: [],
    monthlyIncomeItems: [],
    groupAllocationItems: [],
    assetAllocationItems: [],
    majorSectorChartData: [],
  },
  [IS_LOADING]: false,
}

const getters = {
  [GET_PORTFOLIO_META]: state => state[PORTFOLIO_META],
  [GET_PORTFOLIO_BASIC_VIEW]: state => state[PORTFOLIO_BASIC_VIEW],
  [GET_PORTFOLIO_FULL_VIEW]: state => state[PORTFOLIO_FULL_VIEW],
  [GET_PORTFOLIO_SUMMARY]: state => state[PORTFOLIO_SUMMARY],
  [GET_IS_LOADING]: state => state[IS_LOADING],
}

const mutations = {
  [SET_PORTFOLIO_META](state, data) {
    let create_update_string = ''
    let create_update_string_for_manager = ''
    if (data) {
      if (data.created) {
        create_update_string += `Created: ${moment(data.created).format('MM/DD/YY')}`
      }
      if (data.holding_modified) {
        if (create_update_string) {
          create_update_string += '; '
        }

        create_update_string += `Modified: ${moment(data.holding_modified).format('MM/DD/YY')}`
      }
      create_update_string_for_manager = create_update_string
      if (data.holding_updated_by) {
        create_update_string_for_manager += ` by ${data.holding_updated_by}`
      }
    }
    state[PORTFOLIO_META] = {
      data: { ...data },
      create_update_string: create_update_string,
      create_update_string_for_manager: create_update_string_for_manager,
    }
  },
  [SET_PORTFOLIO_BASIC_VIEW](state, data) {
    const dataItems = getDataTableItems(data.group_data)
    const allGroupsData = getAllGroupsData(data.group_data)

    const updateItems = dataItems
      .filter(item => item.editable)
      .map(item => {
        return { ...item, value: item.value.slice(0, 3) }
      })

    state[PORTFOLIO_BASIC_VIEW] = {
      items: [...dataItems],
      updateItems: [...updateItems],
      footers: getWeightedAvrRow(allGroupsData, data.weighted),
    }
  },
  [SET_PORTFOLIO_FULL_VIEW](state, data) {
    if (data?.group_data) {
      const allGroupsData = getAllGroupsData(data.group_data)

      state[PORTFOLIO_FULL_VIEW] = {
        discountItems: [...getDiscountItems(data.group_data)],
        discountFooters: getDiscountWeightedAvrRow(data.weighted),

        yieldItems: [...getYieldItems(data.group_data)],
        yieldFooters: getYieldWeightedAvrRow(allGroupsData, data.weighted),

        leverageItems: [...getLeverageItems(data.group_data)],
        leverageFooters: getLeverageWeightedAvrRow(data.weighted),

        correlationItems: [...getCorrelationItems(data.group_data)],
        correlationFooters: getCorrelationWeightedAvrRow(data.weighted),

        navTrItems: [...getNavTrItems(data.group_data)],
        navTrFooters: getNavTrWeightedAvrRow(data.weighted),

        allocationsItems: [...getAllocationsItems(data.group_data)],
        allocationsFooters: getAllocationsAvrRow(data.weighted),

        bondItems: [...getBondItems(data.group_data)],
        bondFooters: getBondAvrRow(data.weighted),

        municipalBondItems: [...getMunicipalBondsItems(data.group_data)],
        municipalBondFooters: getMunicipalBondsAvrRow(data.weighted),

        BDCItems: [...getBDCItems(data.group_data)],
        BDCFooters: getBDCAvrRow(data.weighted),

        MLPItems: [...getMLPItems(data.group_data)],
        MLPFooters: getMLPAvrRow(data.weighted),

        holdingItems: [...getHoldingItems(data.group_data)],
        holdingFooters: getHoldingAvrRow(data.weighted),
      }
    }
  },

  [SET_PORTFOLIO_SUMMARY](
    state,
    { weightedAverage, sum, monthlyIncome, allocation, portfolioIndex, bondAndPrefAllocation },
  ) {
    state[PORTFOLIO_SUMMARY] = {
      totalReturnsItems: [...getTotalReturnsItems(weightedAverage)],
      cefBondItems: [...getCefBondItems(weightedAverage)],
      cefBondHeader: [
        `CEF BOND SPECIFIC DATA (${bondAndPrefAllocation}%)`,
        'Weighted Average Data',
      ],
      fullCefItems: [...getFullCefItems(sum, weightedAverage)],
      mlpSpecificItems: [...getMlpSpecificItems(weightedAverage)],
      monthlyIncomeItems: [...getMonthlyIncome(monthlyIncome)],
      groupAllocationItems: allocation,
      fullCefItemsFooter: [
        `* Weighted data relative to full portfolio allocation`,
        `** Marginal Tax Rate: ${portfolioIndex.marginal_tax?.toFixed(
          2,
        )}%, LT Gains Tax Rate: ${portfolioIndex.lt_gains_tax?.toFixed(2)}%`,
      ],

      portfoliosAssetsItems: [
        [
          { key: 'Market Cap', value: `$${formatNumber(sum.market_value, 2)}` },
          { key: 'Net Assets', value: `$${formatNumber(sum.net_assets, 2)}` },
          { key: 'Gross Assets', value: `$${formatNumber(sum.total_assets, 2)}` },
        ],
      ],

      incomeItems: [
        [
          { key: 'Pending Distribution', value: `$${formatNumber(sum.pending_distribution, 2)}` },
          {
            key: 'Est. Annualized Income',
            value: `$${formatNumber(sum.estimated_annualized_income, 2)}`,
          },
          {
            key: 'Est. Monthly Income',
            value: `$${formatNumber(sum.monthly_estimated_annualized_income, 2)}`,
          },
        ],
      ],
      assetAllocationItems: [
        {
          value: weightedAverage.us_equity?.toFixed(2),
          name: 'US Equity',
        },
        {
          value: weightedAverage.non_us_equity?.toFixed(2),
          name: 'Not US Equity',
        },
        {
          value: weightedAverage.us_bonds?.toFixed(2),
          name: 'US Bond',
        },
        {
          value: weightedAverage.non_us_bonds?.toFixed(2),
          name: 'Not US Bond',
        },
        {
          value: weightedAverage.cash?.toFixed(2),
          name: 'Cash',
        },
      ],

      majorSectorChartData: [
        weightedAverage.common_stock_us?.toFixed(1),
        weightedAverage.common_stock_non_us?.toFixed(1),
        weightedAverage.reit_real_estate?.toFixed(1),
        weightedAverage.mlp_alloc?.toFixed(1),
        weightedAverage.bdc_allocation?.toFixed(1),
        weightedAverage.preferred_equity?.toFixed(1),
        weightedAverage.convertible?.toFixed(1),
        weightedAverage.high_yield_alloc?.toFixed(1),
        weightedAverage.senior_loan_alloc?.toFixed(1),
        weightedAverage.inv_grade_alloc?.toFixed(1),
        weightedAverage.municipal_alloc?.toFixed(1),
      ],

      municipalBondHeader: [
        `MUNICIPAL BONDS SPECIFIC DATA (${formatNumber(weightedAverage.muni_allocations, 2)}%)`,
        'Weighted Average Data',
      ],
      municipalBondItems: [
        { key: '2 Year Call Risk', value: `${formatNumber(weightedAverage.bond_call_risk, 2)}%` },
        {
          key: 'AMT%',
          value: `${formatNumber(weightedAverage.amt_pct, 2)}%`,
        },
        {
          key: 'Avg Price per Bond',
          value: `${formatNumber(weightedAverage.average_bond_price, 2)}%`,
        },
      ],
    }
  },

  [SET_IS_LOADING](state, flag) {
    state[IS_LOADING] = flag
  },
}

const actions = {
  async [FETCH_PORTFOLIO_META]({ commit }, id) {
    commit(SET_IS_LOADING, true)

    try {
      const { data } = await axios.get(`cefdata/portfolio/${id}/meta_info/`)
      commit(SET_PORTFOLIO_META, data)
    } catch (error) {
      commit(SET_PORTFOLIO_META, [])
      error.handleGlobally && error.handleGlobally()
    } finally {
      commit(SET_IS_LOADING, false)
    }
  },

  async [FETCH_PORTFOLIO_BASIC_VIEW]({ commit }, id) {
    commit(SET_IS_LOADING, true)

    try {
      const { data } = await axios.get(`cefdata/portfolio-details/${id}/basic-view/`)
      commit(SET_PORTFOLIO_BASIC_VIEW, data)
    } catch (error) {
      commit(SET_PORTFOLIO_BASIC_VIEW, [])
      error.handleGlobally && error.handleGlobally()
    } finally {
      commit(SET_IS_LOADING, false)
    }
  },

  async [FETCH_PORTFOLIO_FULL_VIEW]({ commit }, id) {
    commit(SET_IS_LOADING, true)

    try {
      const { data } = await axios.get(`cefdata/portfolio-details/${id}/full-view/`)

      commit(SET_PORTFOLIO_FULL_VIEW, data)
    } catch (error) {
      commit(SET_PORTFOLIO_FULL_VIEW, [])
      error.handleGlobally && error.handleGlobally()
    } finally {
      commit(SET_IS_LOADING, false)
    }
  },
  async [FETCH_PORTFOLIO_SUMMARY]({ commit }, id) {
    commit(SET_IS_LOADING, true)

    try {
      const { data } = await axios.get(`cefdata/portfolio-details/${id}/summary/`)
      commit(SET_PORTFOLIO_SUMMARY, {
        weightedAverage: data.weighted_average,
        sum: data.sum,
        monthlyIncome: data.monthly_income,
        allocation: data.allocation,
        portfolioIndex: data.portfolio_index,
        bondAndPrefAllocation: data.bond_and_pref_allocation,
      })
    } catch (error) {
      commit(SET_PORTFOLIO_SUMMARY, [])
      error.handleGlobally && error.handleGlobally()
    } finally {
      commit(SET_IS_LOADING, false)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
