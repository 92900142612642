export const Roles = Object.freeze({
  MEMBER: 'member',
  STAFF: 'staff',
  ADMIN: 'admin',
  MANAGER: 'manager',
})

export const RolesColors = Object.freeze({
  [Roles.MEMBER]: 'primary',
  [Roles.STAFF]: 'warning',
  [Roles.ADMIN]: 'info',
  [Roles.MANAGER]: 'danger',
})

export const roleOptions = [
  { label: 'Member', value: Roles.MEMBER },
  { label: 'Staff', value: Roles.STAFF },
  { label: 'Admin', value: Roles.ADMIN },
  { label: 'Manager', value: Roles.MANAGER },
]

export const RolesIcon = Object.freeze({
  [Roles.MEMBER]: 'UserIcon',
  [Roles.STAFF]: 'SettingsIcon',
  [Roles.ADMIN]: 'Edit2Icon',
  [Roles.MANAGER]: 'ServerIcon',
})
