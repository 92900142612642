import axios from '@/libs/axios'

const LOADING = 'LOADING'
const PERFORMANCE = 'PERFORMANCE'
const PERFORMANCE_CHART = 'PERFORMANCE_CHART'
const PERFORMANCE_CURRENT_YEAR = 'PERFORMANCE_CURRENT_YEAR'
const PERFORMANCE_PREVIOUS_YEARS = 'PERFORMANCE_PREVIOUS_YEARS'
const DISCOUNT_CHART = 'DISCOUNT_CHART'
const CHARACTERISTICS = 'CHARACTERISTICS'
const CONSTITUENTS = 'CONSTITUENTS'
const INCEPTION_NAV = 'INCEPTION_NAV'
const NAME = 'NAME'
const DATES_OPTIONS = 'DATES_OPTIONS'
const ASSET_ALLOCATION = 'ASSET_ALLOCATION'
const INDEX_LIST = 'INDEX_LIST'
const PERFORMANCE_LIST = 'PERFORMANCE_LIST'

const SET_LOADING = 'SET_LOADING'
export const SET_PERFORMANCE = 'SET_PERFORMANCE'
export const SET_PERFORMANCE_CHART = 'SET_PERFORMANCE_CHART'
export const SET_PERFORMANCE_CURRENT_YEAR = 'SET_PERFORMANCE_CURRENT_YEAR'
export const SET_PERFORMANCE_PREVIOUS_YEARS = 'SET_PERFORMANCE_PREVIOUS_YEARS'
export const SET_DISCOUNT_CHART = 'SET_DISCOUNT_CHART'
export const SET_CHARACTERISTICS = 'SET_CHARACTERISTICS'
export const SET_CONSTITUENTS = 'SET_CONSTITUENTS'
export const SET_INCEPTION_NAV = 'SET_INCEPTION_NAV'
export const SET_NAME = 'SET_NAME'
export const SET_DATES_OPTIONS = 'SET_DATES_OPTIONS'
export const SET_ASSET_ALLOCATION = 'SET_ASSET_ALLOCATION'
export const SET_INDEX_LIST = 'SET_INDEX_LIST'
export const SET_PERFORMANCE_LIST = 'SET_PERFORMANCE_LIST'
export const SET_DEFAULT_DATA = 'SET_DEFAULT_DATA'

export const GET_LOADING = 'GET_LOADING'
export const GET_PERFORMANCE = 'GET_PERFORMANCE'
export const GET_PERFORMANCE_CHART = 'GET_PERFORMANCE_CHART'
export const GET_PERFORMANCE_CURRENT_YEAR = 'GET_PERFORMANCE_CURRENT_YEAR'
export const GET_PERFORMANCE_PREVIOUS_YEARS = 'GET_PERFORMANCE_PREVIOUS_YEARS'
export const GET_DISCOUNT_CHART = 'GET_DISCOUNT_CHART'
export const GET_CHARACTERISTICS = 'GET_CHARACTERISTICS'
export const GET_CONSTITUENTS = 'GET_CONSTITUENTS'
export const GET_INCEPTION_NAV = 'GET_INCEPTION_NAV'
export const GET_NAME = 'GET_NAME'
export const GET_DATES_OPTIONS = 'GET_DATES_OPTIONS'
export const GET_ASSET_ALLOCATION = 'GET_ASSET_ALLOCATION'
export const GET_INDEX_LIST = 'GET_INDEX_LIST'
export const GET_PERFORMANCE_LIST = 'GET_PERFORMANCE_LIST'

export const FETCH_PORTFOLIO_DETAILS = 'FETCH_PORTFOLIO_DETAILS'
export const FETCH_PERFORMANCE_PREVIOUS_YEARS = 'FETCH_PERFORMANCE_PREVIOUS_YEARS'
export const FETCH_CONSTITUENTS = 'FETCH_CONSTITUENTS'
export const FETCH_DATES_OPTIONS = 'FETCH_DATES_OPTIONS'
export const FETCH_ASSET_ALLOCATION = 'FETCH_ASSET_ALLOCATION'
export const FETCH_INDEX_LIST = 'FETCH_INDEX_LIST'
export const FETCH_PERFORMANCE_LIST = 'FETCH_PERFORMANCE_LIST'
export const RESET_DEFAULT_DATA = 'RESET_DEFAULT_DATA'

const formatObj = obj => {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, v?.toFixed(2)]))
}

const INDEX_LIST_DEFAULT_DATA = {
  diversifiedTableData: {
    items: [],
  },
  equitySectorTableData: {
    items: [],
  },
  taxableBondSectorTableData: {
    items: [],
  },
  taxFreeBondSectorTableData: {
    items: [],
  },
}

const getDefaultData = () => {
  return {
    [LOADING]: false,
    [PERFORMANCE]: [],
    [PERFORMANCE_CHART]: {},
    [PERFORMANCE_CURRENT_YEAR]: {},
    [PERFORMANCE_PREVIOUS_YEARS]: {},
    [DISCOUNT_CHART]: {},
    [CHARACTERISTICS]: {},
    [CONSTITUENTS]: [],
    [INCEPTION_NAV]: null,
    [NAME]: null,
    [ASSET_ALLOCATION]: [],
    [INDEX_LIST]: INDEX_LIST_DEFAULT_DATA,
    [PERFORMANCE_LIST]: INDEX_LIST_DEFAULT_DATA,
  }
}

const state = { ...getDefaultData(), [DATES_OPTIONS]: [] }

const getters = {
  [GET_LOADING]: state => state[LOADING],
  [GET_PERFORMANCE]: state => state[PERFORMANCE],
  [GET_PERFORMANCE_CHART]: state => state[PERFORMANCE_CHART],
  [GET_PERFORMANCE_CURRENT_YEAR]: state => state[PERFORMANCE_CURRENT_YEAR],
  [GET_PERFORMANCE_PREVIOUS_YEARS]: state => state[PERFORMANCE_PREVIOUS_YEARS],
  [GET_DISCOUNT_CHART]: state => state[DISCOUNT_CHART],
  [GET_CHARACTERISTICS]: state => state[CHARACTERISTICS],
  [GET_CONSTITUENTS]: state => state[CONSTITUENTS],
  [GET_INCEPTION_NAV]: state => state[INCEPTION_NAV],
  [GET_NAME]: state => state[NAME],
  [GET_DATES_OPTIONS]: state => state[DATES_OPTIONS],
  [GET_ASSET_ALLOCATION]: state => state[ASSET_ALLOCATION],
  [GET_INDEX_LIST]: state => state[INDEX_LIST],
  [GET_PERFORMANCE_LIST]: state => state[PERFORMANCE_LIST],
}

const mutations = {
  [SET_LOADING](state, flag) {
    state[LOADING] = flag
  },
  [SET_PERFORMANCE](state, data) {
    state[PERFORMANCE] = [
      {
        key: 'Price',
        current: data.price_data.price?.toFixed(2),
        previous_day: data.price_data.pr_day_change_price?.toFixed(2),
        '1_week': data.price_data.one_week_change_price?.toFixed(2),
        '1_month': data.price_data.one_month_change_price?.toFixed(2),
        '3_month': data.price_data.three_month_change_price?.toFixed(2),
        '6_month': data.price_data.six_month_change_price?.toFixed(2),
        '1_year': data.price_data.one_year_change_price?.toFixed(2),
        '3_year': data.price_data.three_year_change_price?.toFixed(2),
        '5_year': data.price_data.five_year_change_price?.toFixed(2),
        '10_year': data.price_data.ten_year_change_price?.toFixed(2),
        mtd: data.price_data.mtd_change_price?.toFixed(2),
        qtd: data.price_data.qtd_change_price?.toFixed(2),
        ytd: data.price_data.ytd_change_price?.toFixed(2),
      },
      {
        key: 'NAV',
        current: data.price_data.nav?.toFixed(2),
        previous_day: data.price_data.pr_day_change_nav?.toFixed(2),
        '1_week': data.price_data.one_week_change_nav?.toFixed(2),
        '1_month': data.price_data.one_month_change_nav?.toFixed(2),
        '3_month': data.price_data.three_month_change_nav?.toFixed(2),
        '6_month': data.price_data.six_month_change_nav?.toFixed(2),
        '1_year': data.price_data.one_year_change_nav?.toFixed(2),
        '3_year': data.price_data.three_year_change_nav?.toFixed(2),
        '5_year': data.price_data.five_year_change_nav?.toFixed(2),
        '10_year': data.price_data.ten_year_change_nav?.toFixed(2),
        mtd: data.price_data.mtd_change_nav?.toFixed(2),
        qtd: data.price_data.qtd_change_nav?.toFixed(2),
        ytd: data.price_data.ytd_change_nav?.toFixed(2),
      },
      {
        key: 'Difference',
        current: '',
        previous_day: data.price_data.pr_day_difference?.toFixed(2),
        '1_week': data.price_data.one_week_difference?.toFixed(2),
        '1_month': data.price_data.one_month_difference?.toFixed(2),
        '3_month': data.price_data.three_month_difference?.toFixed(2),
        '6_month': data.price_data.six_month_difference?.toFixed(2),
        '1_year': data.price_data.one_year_difference?.toFixed(2),
        '3_year': data.price_data.three_year_difference?.toFixed(2),
        '5_year': data.price_data.five_year_difference?.toFixed(2),
        '10_year': data.price_data.ten_year_difference?.toFixed(2),
        mtd: data.price_data.mtd_difference?.toFixed(2),
        qtd: data.price_data.qtd_difference?.toFixed(2),
        ytd: data.price_data.ytd_difference?.toFixed(2),
      },
    ]
  },
  [SET_PERFORMANCE_CHART](state, data) {
    state[PERFORMANCE_CHART] = {
      yMin: data.min_perf,
      xAxisColor: '#757578',
      xAxisData: data.performance_dates,
      series: [
        {
          name: 'Price',
          type: 'line',
          showSymbol: false,
          lineStyle: {
            width: 2,
            color: '#00a6ff',
          },
          color: '#00a6ff',
          data: Object.values(data.performance_price),
        },
        {
          name: 'NAV',
          type: 'line',
          showSymbol: false,
          lineStyle: {
            width: 2,
            color: '#9b230e',
          },
          color: '#9b230e',
          data: Object.values(data.performance_nav),
        },
      ],
    }
  },
  [SET_PERFORMANCE_CURRENT_YEAR](state, data) {
    state[PERFORMANCE_CURRENT_YEAR] = {
      year: data.year_price_data.year,
      data: [
        { key: 'Price', ...formatObj(data.year_price_data.price) },
        { key: 'NAV', ...formatObj(data.year_price_data.nav) },
        { key: 'Difference', ...formatObj(data.year_price_data.difference) },
      ],
    }
  },
  [SET_PERFORMANCE_PREVIOUS_YEARS](state, data) {
    let yearsData = {}

    Object.entries(data).forEach(entry => {
      const [year, yPrice] = entry
      let newPrice = [
        { key: 'Price', ...formatObj(yPrice.price) },
        { key: 'NAV', ...formatObj(yPrice.nav) },
        { key: 'Difference', ...formatObj(yPrice.difference) },
      ]

      yearsData = { [year]: [...newPrice], ...yearsData }
    })

    state[PERFORMANCE_PREVIOUS_YEARS] = yearsData
  },

  [SET_DISCOUNT_CHART](state, data) {
    state[DISCOUNT_CHART] = {
      xAxisColor: '#757578',
      xAxisData: data.discount_dates,
      series: [
        {
          name: 'Relative Index Discount / Premium',
          type: 'line',
          showSymbol: false,
          lineStyle: {
            width: 2,
            color: '#00e682',
          },
          color: '#00e682',
          data: Object.values(data.discount),
        },
      ],
    }
  },
  [SET_CHARACTERISTICS](state, data) {
    state[CHARACTERISTICS] = { ...data.historical_data }
  },
  [SET_CONSTITUENTS](state, items) {
    state[CONSTITUENTS] = [
      ...items.map(el => {
        return { ...el, mk_yield: el.mk_yield?.toFixed(2) }
      }),
    ]
  },
  [SET_INCEPTION_NAV](state, data) {
    state[INCEPTION_NAV] = data.price_data.inception_nav
  },
  [SET_NAME](state, data) {
    state[NAME] = data.name
  },
  [SET_DATES_OPTIONS](state, items) {
    state[DATES_OPTIONS] = [...items]
  },
  [SET_ASSET_ALLOCATION](state, items) {
    state[ASSET_ALLOCATION] = [
      {
        name: 'Asset Breakdown',
        type: 'pie',
        radius: ['0%', '50%'],
        center: ['50%', '30%'],
        avoidLabelOverlap: true,
        label: {
          show: true,
          color: '#000',
        },
        labelLine: {
          show: true,
        },
        data: [...items],
      },
    ]
  },
  [SET_INDEX_LIST](state, data) {
    state[INDEX_LIST] = {
      diversifiedTableData: {
        items: [
          ...data.diversified.map(el => {
            return {
              ...el,
              liq_90: el.liq_90.toLocaleString(),
            }
          }),
        ],
      },
      equitySectorTableData: {
        items: [
          ...data.equity_sector.map(el => {
            return {
              ...el,
              liq_90: el.liq_90.toLocaleString(),
            }
          }),
        ],
      },
      taxableBondSectorTableData: {
        items: [
          ...data.taxable_bond_sector.map(el => {
            return {
              ...el,
              liq_90: el.liq_90.toLocaleString(),
            }
          }),
        ],
      },
      taxFreeBondSectorTableData: {
        items: [
          ...data.tax_free_bond_sector.map(el => {
            return {
              ...el,
              liq_90: el.liq_90.toLocaleString(),
            }
          }),
        ],
      },
    }
  },
  [SET_PERFORMANCE_LIST](state, data) {
    state[PERFORMANCE_LIST] = {
      diversifiedTableData: {
        items: [...data.diversified],
      },
      equitySectorTableData: {
        items: [...data.equity_sector],
      },
      taxableBondSectorTableData: {
        items: [...data.taxable_bond_sector],
      },
      taxFreeBondSectorTableData: {
        items: [...data.tax_free_bond_sector],
      },
    }
  },
  [SET_DEFAULT_DATA](state) {
    Object.assign(state, getDefaultData())
  },
}

const actions = {
  async [FETCH_PORTFOLIO_DETAILS]({ commit }, params) {
    const { id, date } = params
    commit(SET_LOADING, true)
    try {
      const { data } = await axios.get(`cefdata/index-details/${id}/?date=${date}`)
      commit(SET_PERFORMANCE, data)
      commit(SET_INCEPTION_NAV, data)
      commit(SET_NAME, data)
      commit(SET_PERFORMANCE_CURRENT_YEAR, data)
      commit(SET_PERFORMANCE_CHART, data)
      commit(SET_DISCOUNT_CHART, data)
      commit(SET_CHARACTERISTICS, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    } finally {
      commit(SET_LOADING, false)
    }
  },

  async [FETCH_PERFORMANCE_PREVIOUS_YEARS]({ commit }, params) {
    const { id, date } = params
    try {
      const { data } = await axios.get(
        `cefdata/index-details-previous-years-price/${id}/?date=${date}`,
      )

      commit(SET_PERFORMANCE_PREVIOUS_YEARS, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },

  async [FETCH_CONSTITUENTS]({ commit }, params) {
    const { id, date } = params
    try {
      const { data } = await axios.get(`cefdata/index-details-constituents/${id}/?date=${date}`)

      commit(SET_CONSTITUENTS, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },

  async [FETCH_DATES_OPTIONS]({ commit }) {
    try {
      const { data } = await axios.get(`cefdata/index-details-dates/`)
      commit(SET_DATES_OPTIONS, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },

  async [FETCH_ASSET_ALLOCATION]({ commit }, params) {
    const { id, date } = params
    try {
      const { data } = await axios.get(`cefdata/index-details-asset-allocation/${id}/?date=${date}`)

      commit(SET_ASSET_ALLOCATION, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },

  async [FETCH_INDEX_LIST]({ commit }, params) {
    const { date } = params
    try {
      const { data } = await axios.get(`cefdata/index-list/?date=${date}`)
      commit(SET_INDEX_LIST, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },

  async [FETCH_PERFORMANCE_LIST]({ commit }, params) {
    const { date } = params
    try {
      const { data } = await axios.get(`cefdata/index-performance-list/?date=${date}`)
      commit(SET_PERFORMANCE_LIST, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },

  async [RESET_DEFAULT_DATA]({ commit }) {
    commit(SET_DEFAULT_DATA)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
