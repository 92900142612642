import axios from '@/libs/axios'

const IS_LOADING = 'IS_LOADING'
const TAX_DIVIDEND = 'TAX_DIVIDEND'
const SHARE_CLASS = 'SHARE_CLASS'
const SHARE_CLASS_DATA = 'SHARE_CLASS_DATA'
const GROWTH = 'GROWTH'
const GROWTH_WITH_DATE = 'GROWTH_WITH_DATE'
const UNII_SHARE = 'UNII_SHARE'
const EARNINGS = 'EARNINGS'
const RELATED_INDEXES = 'RELATED_INDEXES'
const TOP_HOLDINGS = 'TOP_HOLDINGS'

export const SET_SHARE_CLASS = 'SET_SHARE_CLASS'
export const SET_SHARE_CLASS_DATA = 'SET_SHARE_CLASS_DATA'
export const SET_TAX_DIVIDEND = 'SET_TAX_DIVIDEND'
export const SET_GROWTH = 'SET_GROWTH'
export const SET_GROWTH_WITH_DATE = 'SET_GROWTH_WITH_DATE'
export const SET_UNII_SHARE = 'SET_UNII_SHARE'
export const SET_EARNINGS = 'SET_EARNINGS'
export const SET_RELATED_INDEXES = 'SET_RELATED_INDEXES'
export const SET_TOP_HOLDINGS = 'SET_TOP_HOLDINGS'
export const SET_IS_LOADING = 'SET_IS_LOADING'

export const GET_SHARE_CLASS = 'GET_SHARE_CLASS'
export const GET_SHARE_CLASS_DATA = 'GET_SHARE_CLASS_DATA'
export const GET_TAX_DIVIDEND = 'GET_TAX_DIVIDEND'
export const GET_GROWTH = 'GET_GROWTH'
export const GET_GROWTH_WITH_DATE = 'GET_GROWTH_WITH_DATE'
export const GET_UNII_SHARE = 'GET_UNII_SHARE'
export const GET_EARNINGS = 'GET_EARNINGS'
export const GET_RELATED_INDEXES = 'GET_RELATED_INDEXES'
export const GET_TOP_HOLDINGS = 'GET_TOP_HOLDINGS'
export const GET_IS_LOADING = 'GET_IS_LOADING'

export const FETCH_SHARE_CLASS = 'FETCH_SHARE_CLASS'
export const FETCH_PRIVATE_SHARE_CLASS = 'FETCH_PRIVATE_SHARE_CLASS'
export const FETCH_SHARE_CLASS_DATA = 'FETCH_SHARE_CLASS_DATA'
export const FETCH_TAX_DIVIDEND = 'FETCH_TAX_DIVIDEND'
export const FETCH_GROWTH = 'FETCH_GROWTH'
export const FETCH_GROWTH_WITH_DATE = 'FETCH_GROWTH_WITH_DATE'
export const FETCH_UNII_SHARE = 'FETCH_UNII_SHARE'
export const FETCH_EARNINGS = 'FETCH_EARNINGS'
export const FETCH_TOP_HOLDINGS = 'FETCH_TOP_HOLDINGS'
export const FETCH_RELATED_INDEXES = 'FETCH_RELATED_INDEXES'

const state = {
  [TAX_DIVIDEND]: {},
  [SHARE_CLASS]: {},
  [SHARE_CLASS_DATA]: {},
  [GROWTH]: {},
  [GROWTH_WITH_DATE]: {},
  [UNII_SHARE]: {},
  [EARNINGS]: {},
  [RELATED_INDEXES]: [],
  [TOP_HOLDINGS]: { allocations: [], total: '' },
  [IS_LOADING]: false,
}

const getters = {
  [GET_SHARE_CLASS]: state => state[SHARE_CLASS],
  [GET_SHARE_CLASS_DATA]: state => state[SHARE_CLASS_DATA],
  [GET_TAX_DIVIDEND]: state => state[TAX_DIVIDEND],
  [GET_GROWTH]: state => state[GROWTH],
  [GET_GROWTH_WITH_DATE]: state => state[GROWTH_WITH_DATE],
  [GET_UNII_SHARE]: state => state[UNII_SHARE],
  [GET_EARNINGS]: state => state[EARNINGS],
  [GET_RELATED_INDEXES]: state => state[RELATED_INDEXES],
  [GET_TOP_HOLDINGS]: state => state[TOP_HOLDINGS],
  [GET_IS_LOADING]: state => state[IS_LOADING],
}

const mutations = {
  [SET_SHARE_CLASS_DATA](state, items) {
    let res_data = {}
    items.forEach(function (item) {
      res_data[item.data_type] = { ...item }
    })

    state[SHARE_CLASS_DATA] = res_data
  },
  [SET_SHARE_CLASS](state, items) {
    state[SHARE_CLASS] = { ...items }
  },
  [SET_TAX_DIVIDEND](state, items) {
    state[TAX_DIVIDEND] = { ...items }
  },
  [SET_GROWTH](state, items) {
    state[GROWTH] = { ...items }
  },
  [SET_GROWTH_WITH_DATE](state, items) {
    state[GROWTH_WITH_DATE] = { ...items }
  },
  [SET_UNII_SHARE](state, items) {
    state[UNII_SHARE] = { ...items }
  },
  [SET_EARNINGS](state, items) {
    state[EARNINGS] = { ...items }
  },
  [SET_RELATED_INDEXES](state, items) {
    state[RELATED_INDEXES] = { ...items }
  },
  [SET_TOP_HOLDINGS](state, items) {
    state[TOP_HOLDINGS] = { ...items }
  },
  [SET_IS_LOADING](state, flag) {
    state[IS_LOADING] = flag
  },
}

const actions = {
  async [FETCH_SHARE_CLASS]({ commit }, ticker) {
    try {
      const { data } = await axios.get(`cefdata/shareclass/?tickers=${ticker}`)
      commit(SET_SHARE_CLASS, data.results[0])
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
  async [FETCH_PRIVATE_SHARE_CLASS]({ commit }, id) {
    try {
      const { data } = await axios.get(`cefdata/shareclass/?id=${id}`)
      commit(SET_SHARE_CLASS, data.results[0])
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
  async [FETCH_SHARE_CLASS_DATA]({ commit }, ticker) {
    try {
      const { data } = await axios.get(`cefdata/shareclassdata/?tickers=${ticker}`)
      commit(SET_SHARE_CLASS_DATA, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
  async [FETCH_TAX_DIVIDEND]({ commit }, ticker) {
    try {
      const { data } = await axios.get(`cefdata/taxdividend/${ticker}/`)
      commit(SET_TAX_DIVIDEND, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
  async [FETCH_GROWTH]({ commit }, ticker) {
    try {
      const { data } = await axios.get(`cefdata/growth/${ticker}/`)
      commit(SET_GROWTH, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
  async [FETCH_GROWTH_WITH_DATE]({ commit }, { ticker, startDate, endDate }) {
    try {
      const { data } = await axios.get(
        `cefdata/growth/${ticker}/?start=${startDate}&end=${endDate}`,
      )
      commit(SET_GROWTH_WITH_DATE, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
      throw error
    }
  },
  async [FETCH_UNII_SHARE]({ commit }, ticker) {
    try {
      const { data } = await axios.get(`cefdata/unii/${ticker}/`)
      commit(SET_UNII_SHARE, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
  async [FETCH_EARNINGS]({ commit }, ticker) {
    try {
      const { data } = await axios.get(`cefdata/earnings/${ticker}/`)
      commit(SET_EARNINGS, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
  async [FETCH_RELATED_INDEXES]({ commit }, ticker) {
    try {
      const { data } = await axios.get(`cefdata/related-indexes/${ticker}/`)
      commit(SET_RELATED_INDEXES, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
  async [FETCH_TOP_HOLDINGS]({ commit }, ticker) {
    try {
      const { data } = await axios.get(`cefdata/top-holdings/${ticker}/`)
      commit(SET_TOP_HOLDINGS, data)
    } catch (error) {
      error.handleGlobally && error.handleGlobally()
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
