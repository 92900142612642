import { computed } from 'vue'
import store from '@/store'
import {
  FETCH_COUNTRIES_DATA,
  FETCH_STATES_DATA,
  GET_COUNTRIES_DATA,
  GET_STATES_DATA,
} from '@/store/app/countries'

export const CODE_USA = 'US'

export const useCountries = () => {
  const countries = computed(() => {
    return [...new Set(store.getters[`app/countries/${GET_COUNTRIES_DATA}`])]
  })

  const states = computed(() => {
    return [...new Set(store.getters[`app/countries/${GET_STATES_DATA}`])]
  })

  const fetchCountries = () => {
    return store.dispatch(`app/countries/${FETCH_COUNTRIES_DATA}`)
  }

  const fetchStates = () => {
    return store.dispatch(`app/countries/${FETCH_STATES_DATA}`)
  }
  const getCountryFullName = key => {
    return countries.value.find(item => item.value === key)?.key
  }
  const getStateFullName = key => {
    return states.value.find(item => item.value === key)?.key
  }

  return {
    states,
    countries,
    fetchStates,
    fetchCountries,
    getStateFullName,
    getCountryFullName,
  }
}
